import React, {useEffect, useRef} from 'react';

const NmbrPayrollContainer = () => {
  const containerRef = useRef(null);

  const loadNmbr = () => {
    if (!window.components) {
      setTimeout(loadNmbr, 100);
    } else {
      const frame = window.components.load('run', containerRef.current);

      frame.ready
        .then(() => console.log('Nmbr Components Run Screen loaded'))
        .catch((err) =>
          console.error('Could not load Nmbr Components Run Screen', err),
        );
    }
  };

  useEffect(() => {
    loadNmbr();
  }, []);

  return (
    <div style={{height: '100vh'}} ref={containerRef}></div>
  );
};

export default NmbrPayrollContainer;
