import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Select2} from 'components';
import {t} from 'shared/core';
import Explanation from './Explanation';

const EffectiveDate = observer(({uiState, updateForecast}) => {
  const {effectiveDateType, effectiveDateOptions, confirmationSummary} = uiState;

  return (
    <FormLayout>
      <Select2
        label='time_off.policy.edit.Effective Date'
        value={effectiveDateType}
        onChange={e => uiState.updateEffectiveDateType(e.target.value, updateForecast)}
        disabled={confirmationSummary && confirmationSummary.accrualStart === 'policy_holder_start_date'}
      >
        {effectiveDateOptions.map(effectiveDateOption =>
          <option key={effectiveDateOption.label} value={effectiveDateOption.type}>{t(effectiveDateOption.label, {date: effectiveDateOption.dateView})}</option>)
        }
      </Select2>
      <Explanation uiState={uiState} effectiveDateType={effectiveDateType}/>
    </FormLayout>
  );
});

export default EffectiveDate;
