import React from 'react';
import {observer} from 'mobx-react';
import {t, types, endpoints} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {EmployeeNameWithAvatar, AvatarList, Layout, InteractiveTable, DropdownFilter} from 'components';
import {PerformanceReviewRevieweeSummary} from 'stores/performance_reviews';
import CycleName from './CycleName';

const COLUMNS = [
  {
    header: 'performance_reviews.view.Reviewee',
    render: (model => <EmployeeNameWithAvatar to={`${model.link}/reviewees`} employee={model.employee} size='sm'/>),
    width: 3
  },
  {
    header: 'performance_reviews.view.Cycle',
    render: model => <CycleName cycle={model.cycle}/>,
    width: 3
  },
  {
    header: 'performance_reviews.view.Reviewers',
    render: (model => <AvatarList limit={3} moreModalEnabled employees={model.reviewers}/>),
    width: 2
  },
  {
    header: 'performance_reviews.view.Due Date',
    render: (model => model.cycle.dueDateView),
    width: 2
  },
  {
    header: 'performance_reviews.view.Status',
    render: (model => <FormattedMessage
              id='performance_reviews.view.REVIEWS_RECEIVED'
              values={{received: model.completedReviews, total: model.totalReviews}}/>),
    width: 2,
  }
];

const LOCALIZATION = {
  emptyState: 'performance_reviews.directory.No performance reviews to display',
};

function statusFilters() {
  return [
    {
      id: 'shared',
      display: t('performance_reviews.directory.Shared')
    },
    {
      id: 'not_shared',
      display: t('performance_reviews.directory.Open')
    }
  ];
}

const RevieweesDirectory = observer(() => {
  return (
    <Layout>
      <InteractiveTable
        title={t('performance_reviews.Performance reviews')}
        columns={COLUMNS}
        localization={LOCALIZATION}
        showLinks={false}
        saveStateToUrl
        searchable
        proxy={{
          type: 'async',
          modelType: types.PERFORMANCE_REVIEWS.REVIEWEE,
          model: PerformanceReviewRevieweeSummary,
          endpoint: endpoints.PERFORMANCE_REVIEWS.REVIEWEES.ALL
        }}
      >
        <DropdownFilter options={statusFilters()} attr='status'/>
      </InteractiveTable>
    </Layout>
  );
});

export default RevieweesDirectory;
