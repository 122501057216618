import Suggestion from './Suggestion';
import _ from 'lodash';
import {auth, t} from 'shared/core';
import uuid from 'uuid';

const NAVIGATION_ITEMS = [
  {
    caption: 'Home',
    url: '/home',
    icon: 'home',
    enabled: auth.moduleEnabled('directory'),
    keywords: [
      'Dashboard'
    ]
  },
  {
    caption: 'Inbox',
    url: '/inbox',
    icon: 'inbox',
    enabled: auth.moduleEnabled('inbox'),
    keywords: [
      'Tasks'
    ]
  },

  // MY PROFILE
  {
    caption: 'My Personal Information',
    url: `/employees/${auth.employee.id}/personal`,
    icon: 'face',
    enabled: (auth.moduleEnabled('directory') || auth.moduleEnabled('benefits')) && auth.user.employee,
    detail: 'My Profile',
    keywords: [
      'My Name',
      'Address',
      'Contact Information'
    ],
    weight: 0.7
  },
  {
    caption: 'Request Time Off',
    url: `/employees/${auth.employee.id}/time-off/request`,
    icon: 'event',
    enabled: auth.moduleEnabled('pto') && auth.employee.hasTimeOffPolicy,
    detail: 'My Profile',
    keywords: [
      'Time Off',
      'Vacation'
    ],
    weight: 0.9
  },
  {
    caption: 'My Banking Information',
    url: `/employees/${auth.employee.id}/banking`,
    icon: 'account_balance',
    enabled: auth.moduleEnabled('directory') && auth.user.employee,
    detail: 'My Profile',
    keywords: [
      'Bank Account',
      'Social Insurance Number',
      'SIN'
    ],
    weight: 0.7
  },
  {
    caption: 'My Time Off',
    url: `/employees/${auth.employee.id}/time-off`,
    icon: 'date_range',
    enabled: auth.moduleEnabled('pto') && auth.user.employee,
    detail: 'My Profile',
    keywords: [
      'Vacation',
      'Time Off',
      'Upcoming Time Off'
    ],
    weight: 0.7
  },
  {
    caption: 'My Documents',
    url: `/employees/${auth.employee.id}/documents`,
    icon: 'folder_open',
    enabled: auth.moduleEnabled('directory') && auth.user.employee,
    detail: 'My Profile',
    keywords: [
      'Documents',
      'Offer Letter',
      'Employment Contract'
    ],
    weight: 0.7
  },
  {
    caption: 'My Calendars',
    url: `/employees/${auth.employee.id}/calendars`,
    icon: 'date_range',
    enabled: auth.moduleEnabled('directory') && auth.user.employee,
    detail: 'My Profile',
    weight: 0.7
  },

  {
    caption: 'People',
    url: '/employees',
    icon: 'format_list_bulleted',
    enabled: auth.moduleEnabled('directory'),
    keywords: [
      'Employees'
    ]
  },
  {
    caption: 'Org Chart',
    url: '/employees/orgchart',
    icon: '',
    enabled: auth.moduleEnabled('directory') && auth.company.orgChartEnabled,
    keywords: [
      'Organizational Chart'
    ]
  },
  {
    caption: 'Hire an employee',
    url: '/employees/hire',
    icon: 'person_add',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::HIRE_EMPLOYEES'),
    weight: 0.9
  },

  {
    caption: 'Benefits',
    url: '/benefits',
    enabled: auth.moduleEnabled('benefits') && auth.hasAccess('::MANAGE_BENEFITS'),
    icon: 'favorite_border'
  },

  // TIME OFF
  {
    caption: 'Time Off Summaries',
    url: '/time_off/account_summaries',
    enabled: auth.moduleEnabled('pto') && auth.hasAccess('::MANAGE_TIME_OFF'),
    icon: 'access_time',
    detail: 'Time Off'
  },
  {
    caption: 'Pending Time Off Requests',
    url: '/time_off/pending_requests',
    enabled: auth.moduleEnabled('pto')&& auth.hasAccess('::MANAGE_TIME_OFF'),
    icon: 'access_time',
    detail: 'Time Off',
    keywords: [
      'Pending Requests'
    ]
  },
  {
    caption: 'Time Off Policies',
    url: '/time_off/policies',
    enabled: auth.moduleEnabled('pto') && auth.hasAccess('::MANAGE_TIME_OFF'),
    icon: 'access_time',
    detail: 'Time Off'
  },
  {
    caption: 'Holidays',
    url: '/time_off/holidays',
    enabled: auth.moduleEnabled('pto') && auth.hasAccess('::MANAGE_TIME_OFF'),
    icon: 'access_time',
    detail: 'Time Off'
  },

  // PERFORMANCE
  {
    caption: 'Reviews',
    url: '/reviews',
    icon: 'reviews',
    enabled: auth.moduleEnabled('performance') && auth.hasAccess('::MANAGE_PERFORMANCE_MANAGEMENT'),
    keywords: [
      'Performance Reviews'
    ],
    detail: 'Performance'
  },
  {
    caption: 'Goals',
    url: '/goals',
    icon: 'flag',
    enabled: auth.moduleEnabled('performance') && auth.hasAccess('::MANAGE_PERFORMANCE_MANAGEMENT'),
    detail: 'Performance'
  },
  {
    caption: 'Check-Ins',
    url: '/performance',
    icon: 'group_work',
    enabled: auth.moduleEnabled('performance') && auth.hasAccess('::MANAGE_PERFORMANCE_MANAGEMENT'),
    keywords: [
      'Check Ins'
    ],
    detail: 'Performance'
  },

  {
    caption: 'Surveys',
    url: '/surveys',
    icon: 'poll',
    enabled: auth.moduleEnabled('surveys') && auth.hasAccess('::MANAGE_SURVEYS'),
    keywords: [
      'Polls'
    ],
  },

  {
    caption: 'Time Tracking',
    url: '/time_tracking',
    icon: 'timer',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_TIME_TRACKING'),
    keywords: [
      'Clock In',
      'Clocking In',
      'Timesheets'
    ]
  },

  {
    caption: 'Training',
    url: '/training',
    icon: 'book',
    enabled: auth.moduleEnabled('training') && auth.hasAccess('::MANAGE_TRAINING')
  },

  {
    caption: 'Recruiting',
    url: '/recruiting',
    icon: 'search',
    enabled: auth.moduleEnabled('training') && auth.hasAccess('::MANAGE_ATS'),
    keywords: [
      'ATS',
      'Applicant Tracking System'
    ]
  },

  {
    caption: 'Offers',
    url: '/offers',
    icon: 'assignment',
    enabled: auth.moduleEnabled('ats') && auth.hasAccess('::MANAGE_ATS')
  },

  // REPORTS
  {
    caption: 'Reports',
    url: '/reports',
    icon: 'insert_chart',
    enabled: auth.moduleEnabled('directory') && auth.user.admin
  },
  {
    caption: 'Custom Reports',
    url: '/reports/custom',
    icon: 'insert_chart',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    detail: 'Reports'
  },
  {
    caption: 'Department Profile',
    url: '/reports/employees/departments',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::DEPARTMENT_PROFILE_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Age Profile',
    url: '/reports/employees/age-demographics',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::AGE_PROFILE_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Tenure Profile',
    url: '/reports/employees/tenure',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::TENURE_PROFILE_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Gender Profile',
    url: '/reports/employees/gender-demographics',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::GENDER_PROFILE_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Headcount',
    url: '/reports/headcount',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::HISTORICAL_HEADCOUNT_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Turnover',
    url: '/reports/turnover',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::TURNOVER_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'New Hires And Terminations',
    url: '/reports/employees/new-hires-and-terminations',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::NEW_HIRES_AND_TERMINATIONS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Active Employees',
    url: '/reports/personnel',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::ACTIVE_EMPLOYEES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Employees On Leave',
    url: '/reports/employees-on-leave',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::ON_LEAVE_EMPLOYEES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Terminated Employees',
    url: '/reports/terminated-employees',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::TERMINATED_EMPLOYEES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Emergency Contacts',
    url: '/reports/emergency-contacts',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::EMERGENCY_CONTACT_INFO_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Time Off Balances',
    url: '/reports/time-off/balances',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::TIME_OFF_BALANCES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Time Off Liability',
    url: '/reports/time-off/liability',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::VACATION_LIABILITY_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Time Off Manual Adjustments',
    url: '/reports/time-off/manual-adjustments',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::MANUAL_ADJUSTMENTS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Time Off Requests',
    url: '/reports/time-off/requests',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::TIME_OFF_REQUESTS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Payroll Reconciliation',
    url: '/reports/time-off/reconciliation',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::PAYROLL_RECONCILIATION_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Tracked Hours Per Project',
    url: '/reports/time-tracking/tracked-hours',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::PROJECT_HOURS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Employee Goals',
    url: '/reports/performance/employee-goals',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::EMPLOYEE_GOALS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Check-Ins',
    url: '/reports/performance/checkins',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::CHECKINS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Performance Reviews',
    url: '/reports/performance/performance-reviews',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::PERFORMANCE_REVIEWS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Survey Responses',
    url: '/reports/surveys',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::SURVEYS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Survey Responses (Anonymous)',
    url: '/reports/anonymous-surveys',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::ANONYMOUS_SURVEYS_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Training Summary',
    url: '/reports/training/summary',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::TRAINING_SUMMARY_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Training Responses',
    url: '/reports/training/responses',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::TRAINING_RESPONSES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Candidates',
    url: '/reports/recruiting/candidates',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::CANDIDATES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Candidates By Source',
    url: '/reports/recruiting/candidates-by-source',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::CANDIDATES_BY_SOURCE_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Payroll Updates',
    url: '/payroll_updates',
    enabled: auth.moduleEnabled('payroll') && auth.hasAccess('::PAYROLL_UPDATES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Benefit Updates',
    url: '/benefits/updates',
    enabled: auth.moduleEnabled('benefits') && auth.hasAccess('::BENEFIT_UPDATES_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },
  {
    caption: 'Dependent Benefits Eligibility',
    url: '/reports/benefits/dependent-eligibility',
    enabled: auth.moduleEnabled('directory') && auth.hasAccess('::DEPENDENT_ELIGIBILITY_REPORT'),
    icon: 'insert_chart',
    detail: 'Reports'
  },

  {
    caption: 'Payroll',
    url: '/payroll',
    enabled: auth.moduleEnabled('payroll') && auth.hasAccess('::PAYROLL'),
    icon: 'attach_money',
    detail: 'Reports'
  },


  // COMPANY SETTINGS
  {
    caption: 'Company Settings',
    url: '/company_settings',
    enabled: auth.moduleEnabled('account_management') && auth.user.admin,
    icon: 'info_outline'
  },
  {
    caption: 'General Info',
    url: '/company_settings/general',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'business',
    detail: 'Company Settings',
    keywords: [
      'Locations',
      'Offices',
      'Company Logo'
    ]
  },
  {
    caption: 'Departments',
    url: '/company_settings/departments',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'group',
    detail: 'Company Settings'
  },
  {
    caption: 'Documents',
    url: '/company_settings/documents',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'folder_open',
    detail: 'Company Settings',
    keywords: [
      'Files'
    ]
  },
  {
    caption: 'Document Folders',
    url: '/company_settings/document_folders',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'folder_open',
    detail: 'Company Settings'
  },
  {
    caption: 'Signatures',
    url: '/company_settings/documents',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'assignment_ind',
    detail: 'Company Settings'
  },
  {
    caption: 'Calendars',
    url: '/company_settings/calendars',
    enabled: auth.moduleEnabled('pto') && auth.user.admin,
    icon: 'date_range',
    detail: 'Company Settings'
  },
  {
    caption: 'Reminders',
    url: '/company_settings/reminders',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'date_range',
    detail: 'Company Settings'
  },
  {
    caption: 'Visibility Preferences',
    url: '/company_settings/visibility',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'insert_link',
    detail: 'Company Settings'
  },
  {
    caption: 'Leave Types',
    url: '/company_settings/leave_types',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'access_time',
    detail: 'Company Settings'
  },
  {
    caption: 'Onboarding Tasks',
    url: '/company_settings/tasks/onboarding',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'person_add',
    detail: 'Company Settings'
  },
  {
    caption: 'Offboarding Tasks',
    url: '/company_settings/tasks/offboarding',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'person_remove',
    detail: 'Company Settings'
  },
  {
    caption: 'Email Templates',
    url: '/company_settings/company_email_templates',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'email',
    detail: 'Company Settings'
  },
  {
    caption: 'Offer Templates',
    url: '/company_settings/offer_templates',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'assignment',
    detail: 'Company Settings'
  },
  {
    caption: 'Custom Fields',
    url: '/company_settings/custom_fields',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'short_text',
    detail: 'Company Settings'
  },
  {
    caption: 'Employee Groups',
    url: '/company_settings/employee_groups',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'people_outline',
    detail: 'Company Settings'
  },
  {
    caption: 'Company Links',
    url: '/company_settings/company_links',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'insert_link',
    detail: 'Company Settings'
  },
  {
    caption: 'Company Files',
    url: '/company_settings/company_files',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'insert_drive_file',
    detail: 'Company Settings'
  },
  {
    caption: 'Roles and Permissions',
    url: '/company_settings/permissions',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'security',
    detail: 'Company Settings'
  },
  {
    caption: 'Users',
    url: '/company_settings/users',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'exit_to_app',
    detail: 'Company Settings'
  },
  {
    caption: 'Authentication',
    url: '/company_settings/authentications',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'lock',
    detail: 'Company Settings'
  },
  {
    caption: 'Billing',
    url: '/company_settings/billing/',
    enabled: auth.moduleEnabled('billing') && auth.hasAccess('::MANAGE_BILLING'),
    icon: 'credit_card',
    detail: 'Company Settings',
    keywords: [
      'Credit Card'
    ]
  },

  // Integrations
  {
    caption: 'Zapier',
    url: '/company_settings/apps/zapier',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },
  {
    caption: 'Okta',
    url: '/company_settings/apps/okta',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },
  {
    caption: 'Google',
    url: '/company_settings/apps/google',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },
  {
    caption: 'Microsoft',
    url: '/company_settings/apps/microsoft',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },
  {
    caption: 'Slack',
    url: '/company_settings/apps/slack',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },
  {
    caption: 'JazzHR',
    url: '/company_settings/apps/jazz_hr',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },
  {
    caption: 'Greenhouse',
    url: '/company_settings/apps/greenhouse',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },
  {
    caption: 'Indeed',
    url: '/recruiting/job-site',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'link',
    detail: 'Company Settings'
  },

  {
    caption: 'Mass Updates',
    url: '/mass_updates',
    enabled: auth.moduleEnabled('directory') && auth.user.admin,
    icon: 'edit',
    detail: 'Company Settings'
  },

  // PERSONAL PREFERENCES
  {
    caption: 'Language',
    url: '/user_settings',
    icon: 'translate',
    detail: 'Personal Settings'
  },
  {
    caption: 'Change My Password',
    url: '/user_settings',
    icon: 'language',
    detail: 'Personal Settings'
  },
  {
    caption: 'My Emails',
    url: '/user_settings',
    icon: 'email',
    detail: 'Personal Settings'
  },
];

export default () => {
  return _.chain(NAVIGATION_ITEMS)
    .reject(i => i.enabled === false)
    .map(i => new Suggestion({
      id: uuid.v4(),
      suggestionType: 'navigation',
      keywords: i.keywords ? i.keywords.map(k => t(`global_search.keywords.${k}`)) : [],
      weight: i.weight,
      args: {
        ...i,
        caption: t(`global_search.items.${i.caption}`),
        detail: i.detail ? t(`global_search.detail.${i.detail}`) : ''
      }
    }))
    .value();
};
