import React from 'react';
import {observer} from 'mobx-react';
import SetupState from '../state/SetupState';
import {withState} from 'shared/core';
import {Layout, Panel, Display, Button} from 'components';

const Setup = observer(({uiState}) => {
  return (
    <div>
      <Display title='Collage Payroll'/>
      <Layout>
        <Layout.Section>
          <Panel>
            <Panel.Header title='Set Up Collage Payroll'/>
            <div className='waterloo pb4'>
              {'Ready to start using Collage Payroll? Let\'s get you started!'}
            </div>
            <Button size='lg' onClick={() => uiState.setup()}>
              {'Get Started'}
            </Button>
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(Setup, SetupState);
