import React from 'react';
import {observer} from 'mobx-react';
import {t, endpoints} from 'shared/core';
import {Panel, EmployeeTableSelector} from 'components';
import PolicyConfirmationModal from '../PolicyConfirmationModal';
import useModal from 'shared/hooks/useModal';

const Employees = observer(({uiState, submitStep}) => {
  const policyConfirmationModalOpen = useModal(uiState, 'PolicyConfirmationModal');
  const {availableEmployees, selectedEmployees, newSelectedEmployees, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_off.policy.edit.Add Employees')}
        description={t('time_off.policy.edit.Choose the employees who should be enrolled under this time off policy.')}
      />
      <EmployeeTableSelector
        employees={availableEmployees}
        includedEmployees={selectedEmployees}
        onChange={employees => uiState.updateSelectedEmployees(employees)}
        errorMessage={errors.employees}
        endpoint={endpoints.EMPLOYEE_DIRECTORY.UNPAGINATED}
        filters={['pto_policy', 'location', 'department', 'employment_type']}
      />
      <PolicyConfirmationModal
        uiState={uiState}
        description="time_off.policy.edit.Choose when you'd like new enrollments to be effective from and preview how they will impact employee balances for those who are in other policies."
        isOpen={policyConfirmationModalOpen}
        employees={newSelectedEmployees}
        onSave={submitStep}
        updateForecast={() => uiState.forecastPolicy()}
      />
    </div>
  );
});

export default Employees;
