import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, types, endpoints} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {EmployeeNameWithAvatar, Display, Layout, InteractiveTable} from 'components';
import {Employee} from 'stores/employees';
import EnrolEmployeeModal from './EnrolEmployeeModal';
import useModal from 'shared/hooks/useModal';
import {NmbrEmployeesDirectoryState} from '../state';

const COLUMNS = [
  {
    header: 'nmbr.employees.directory.Employee',
    render: model => <EmployeeNameWithAvatar employee={model} size='sm'/>,
    width: 8,
    sortBy: 'name'
  },
  {
    header: 'nmbr.employees.directory.Enrolled in nmbr',
    render: model => model.nmbrConfigured ? <FormattedMessage id='Yes'/> : <FormattedMessage id='No'/>,
    width: 3
  }
];

const LOCALIZATION = {
  emptyState: 'mass_updates.directory.No employees to display'
};

const NmbrEmployeesDirectory = observer(({uiState}) => {
  const enrolEmployeeModalOpen = useModal(uiState, 'EnrolEmployeeModal', 'selectedEmployee', null, 'errors');

  return (
    <div>
      <Display
        title={t('nmbr.employees.directory.Employees')}
      />
      <Layout>
        <InteractiveTable
          uiState={uiState}
          columns={COLUMNS}
          localization={LOCALIZATION}
          searchable={false}
          saveStateToUrl
          onMount={agent => uiState.setInteractiveAgent(agent)}
          customLinks={model => uiState.customLinksFor(model)}
          proxy={{
            type: 'async',
            modelType: types.EMPLOYEE,
            model: Employee,
            endpoint: endpoints.NMBR.EMPLOYEES.ALL
          }}
        />
        <EnrolEmployeeModal uiState={uiState} modalOpen={enrolEmployeeModalOpen}/>
      </Layout>
    </div>
  );
});

export default withState(NmbrEmployeesDirectory, NmbrEmployeesDirectoryState);
