import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Panel, FormLayout, Input, QuickTip, NumberInput, Select2, LabelHelper, FormError} from 'components';
import moment from 'moment';
import _ from 'lodash';

const Details = observer(({uiState}) => {
  const {policy, hoursPerWorkday, workDayLengthChanged, errors} = uiState;
  
  return (
    <div>
      <Panel.Header 
        title={t('time_off.policy.edit.steps.Policy Details')} 
        description={t('time_off.policy.edit.Start by setting up the basic details of your policy.')}
      />
      <FormLayout>
        <Input
          label='time_off.policy.edit.Policy Name'
          value={policy.name}
          onChange={e => policy.merge({name: e.target.value})}
          errorMessage={errors.name}
        />
        {workDayLengthChanged &&
          <QuickTip
            trait='caution'
            header={t('time_off.policy.edit.HOURS_PER_WEEK_CHANGING_HEADER')}
            paragraphs={[
              <FormattedMessage id='time_off.policy.edit.HOURS_PER_WEEK_CHANGING_EXPLANATION_1'/>,
              <FormattedMessage id='time_off.policy.edit.HOURS_PER_WEEK_CHANGING_EXPLANATION_2'/>
            ]}
          />
        }
        <div className='flex'>
          <div className='col-6 mr2'>
            <NumberInput
              label='time_off.policy.edit.Hours per workday'
              value={hoursPerWorkday}
              onChange={e => uiState.updateHoursPerWorkday(e.target.value)}
              errorMessage={errors.workDayLength}
              step='0.01'
              min='0'
              max='24'
            />
          </div>
          <div className='col-6'>
            <LabelHelper label={t('time_off.policy.edit.Policy Start Date')}/>
            <div className='flex space-x'>
              <div className='flex-grow'>
                <Select2 value={policy.startMonth} onChange={e => policy.merge({startMonth: e.target.value})}>
                  {moment.months().map((month, index) => <option value={index + 1} key={index}>{month}</option>)}
                </Select2>
              </div>
              <NumberInput
                value={policy.startDay}
                onChange={e => policy.merge({startDay: e.target.value})}
                errorMessage={errors.startDay}
                min='1'
                max={uiState.daysForStartMonth}
              />
            </div>
          </div>
        </div>
        <div>
          <LabelHelper label={t('time_off.policy.edit.Work Week')}/>
          <div className='flex flex-wrap gap-2'>
            {moment.weekdaysShort().map((day, index) => (
              <div className='Workday'>
                <div className={`checkbox ${uiState.workdaySelected(index) ? 'selected' : ''}`} onClick={() => uiState.toggleWorkWeekDay(index)}>
                  {day}
                </div>
              </div>
            ))}
          </div>
          <FormError message={errors.workWeekDays}/>
        </div>
      </FormLayout>
    </div>
  );
});

export default Details;
