import React from 'react';
import {observer} from 'mobx-react';
import {QuickTip, ContactUsLink} from 'components';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';

const HelpArticleLink = () => {
  return (
    <a target='_blank' href='http://support.collage.co/time-off/updating-a-time-off-policy'>
      <FormattedMessage id='time_off.policy.edit.confirmation.Read more'/>
    </a>
  );
};

const Explanation = observer(({uiState, effectiveDateType}) => {
  const {confirmationSummary, effectiveDate} = uiState;
  const date = <span className='medium'>{calendarDate(effectiveDate)}</span>;

  if (confirmationSummary && confirmationSummary.accrualStart === 'policy_holder_start_date') {
    let paragraphs = [
      <FormattedMessage id='time_off.policy.edit.confirmation.POLICY_HOLDER_START_DATE_EXPLANATION_2' values={{contactUs: <ContactUsLink/>}}/>
    ];
    if (confirmationSummary.accrualFrequency === 'annual') {
      paragraphs = [
        <FormattedMessage id='time_off.policy.edit.confirmation.POLICY_HOLDER_START_DATE_EXPLANATION_1'/>,
        ...paragraphs
      ];
    }
    return (
      <QuickTip
        header={<FormattedMessage id='time_off.policy.edit.confirmation.POLICY_HOLDER_START_DATE_HEADER'/>}
        trait='caution'
        paragraphs={paragraphs}
      />
    );
  }

  if (effectiveDateType === 'startOfPolicyYear') {
    return (
      <QuickTip
        header={<FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_HEADER' values={{date}}/>}
        trait='caution'
        paragraphs={[
          <FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_1'/>,
          <ul className='pl2'>
            <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_2' values={{date}}/></li>
            <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_3' values={{date}}/></li>
            <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_4' values={{date}}/></li>
            <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_5' values={{
              date,
              link: <HelpArticleLink/>
            }}/></li>
          </ul>
        ]}
      />
    );
  }
});

export default Explanation;
