import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import _ from 'lodash';

class EmployeeTableSelectorState {
  store = new DomainStore();
  onChange;

  @observable includedEmployees = [];

  @observable highlightedIncludedEmployeeIds = [];
  @observable highlightedAvailableEmployeeIds = [];
  @observable errorMessage;

  @observable interactiveContextState = {};
  @observable agent;

  receiveProps({onChange, includedEmployees, errorMessage}) {
    this.onChange = onChange;
    this.includedEmployees = includedEmployees || [];
    this.errorMessage = errorMessage;
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action updateInteractiveContextState(interactiveContextState) {
    this.interactiveContextState = interactiveContextState;
  }

  @action addEmployees() {
    this.includedEmployees = _.union(this.includedEmployees, this.highlightedAvailableEmployees);

    this.highlightedIncludedEmployeeIds = this.highlightedAvailableEmployeeIds;
    this.highlightedAvailableEmployeeIds = [];

    this.onChange(this.uniqueIncludedEmployees);
  }

  @action removeEmployees() {
    this.includedEmployees = _.reject(this.includedEmployees, e => _.includes(this.highlightedIncludedEmployeeIds, e.id));

    this.highlightedAvailableEmployeeIds = this.highlightedIncludedEmployeeIds;
    this.highlightedIncludedEmployeeIds = [];

    this.onChange(this.uniqueIncludedEmployees);
  }

  @action addAllEmployees() {
    this.includedEmployees = _.union(this.includedEmployees, this.availableEmployees);

    this.highlightedAvailableEmployeeIds = [];

    this.onChange(this.uniqueIncludedEmployees);
  }

  @action removeAllEmployees() {
    this.includedEmployees = [];

    this.onChange(this.uniqueIncludedEmployees);
  }

  @action updateHighlightedAvailableEmployeeIds(selectedOptions) {
    this.highlightedAvailableEmployeeIds = selectedOptions.map(option => option.value);
  }

  @action updateHighlightedIncludedEmployeeIds(selectedOptions) {
    this.highlightedIncludedEmployeeIds = selectedOptions.map(option => option.value);
  }

  @computed get filteredEmployees() {
    return this.interactiveContextState.data;
  }

  @computed get availableEmployees() {
    return _.differenceBy(this.filteredEmployees, this.includedEmployees, 'id');
  }

  @computed get filteredIncludedEmployees() {
    return _.filter(this.filteredEmployees, e => _.includes(this.includedEmployeeIds, e.id));
  }

  @computed get highlightedAvailableEmployees() {
    return _.filter(this.filteredEmployees, e => _.includes(this.highlightedAvailableEmployeeIds, e.id));
  }

  @computed get highlightedIncludedEmployees() {
    return _.filter(this.filteredEmployees, e => _.includes(this.highlightedIncludedEmployeeIds, e.id));
  }

  @computed get availableEmployeeIds() {
    return _.map(this.availableEmployees, 'id');
  }

  @computed get includedEmployeeIds() {
    return _.map(this.includedEmployees, 'id');
  }

  @computed get uniqueIncludedEmployees() {
    return _.uniqBy(this.includedEmployees, 'id');
  }

  @computed get canAddAll() {
    return this.availableEmployees.length > 0;
  }

  @computed get canRemoveAll() {
    return this.filteredIncludedEmployees.length > 0;
  }

  @computed get canAdd() {
    return this.highlightedAvailableEmployeeIds.length > 0;
  }

  @computed get canRemove() {
    return this.highlightedIncludedEmployeeIds.length > 0;
  }

  @computed get sortedAvailableEmployees() {
    return _.orderBy(this.availableEmployees, 'name');
  }

  @computed get sortedFilteredIncludedEmployees() {
    return _.orderBy(this.filteredIncludedEmployees, 'name');
  }
}

export default EmployeeTableSelectorState;
