import _ from 'lodash';
import {observable, computed, action} from 'mobx';
import {DomainObject} from 'shared/store';
import {fullName} from 'shared/tools';
import BankAccount from 'stores/bank_account/BankAccount';
import AttachedDocument from 'stores/documents/AttachedDocument';
import EmployeeCustomField from 'stores/employee_custom_fields/EmployeeCustomField';
import {EmployeeBenefitEnrollment, EmployeeInsuranceNumber} from 'stores/benefits';
import {TimeOffPolicy} from 'stores/time_off';

class Employee extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable jobTitle;
  @observable preferredName;
  @observable preferredLastName;
  @observable status;
  @observable location;
  @observable department;
  @observable user;
  @observable avatarColour;
  @observable avatarInitials;
  @observable employmentType;

  @observable middleName;
  @observable workPhone;
  @observable workPhoneExtension;
  @observable personalPhone;
  @observable workEmail;
  @observable personalEmail;
  @observable gender;
  @observable customGender;
  @observable birthDate;
  @observable homeAddress1;
  @observable homeAddress2;
  @observable postalCode;
  @observable city;
  @observable countryCode;
  @observable regionCode;

  @observable sin;
  @observable sinExpiryDate;
  @observable bankAccount;

  @observable ec1Name;
  @observable ec1Relationship;
  @observable ec1Phone;
  @observable ec2Name;
  @observable ec2Relationship;
  @observable ec2Phone;
  @observable employeeCustomFields;

  @observable invitationEmail;

  @observable avatar;

  @observable startDate;
  @observable payRate;
  @observable payRateUnit;
  @observable payRateCurrency;
  @observable payFrequency;
  @observable hoursPerWeek;
  @observable commission;
  @observable commissionStructure;
  @observable annualizedCommission;
  @observable bonus;
  @observable bonusStructure;

  @observable employeeBenefitEnrollments;
  @observable employeeInsuranceNumbers;
  @observable collectTax;
  @observable taxLocale;
  @observable collectPayroll;
  @observable collectSin;
  @observable payrollEnabled;

  @observable timeOffPolicy;

  @observable employmentStatus;
  @observable onboardingStatus;

  @observable nmbrConfigured;

  @action merge(other, fields) {
    super.merge(other, {
      ...fields,
      bankAccount: BankAccount,
      employeeCustomFields: [EmployeeCustomField],
      avatar: AttachedDocument,
      timeOffPolicy: TimeOffPolicy,
      _dates: ['sinExpiryDate', 'birthDate', 'startDate'],
      employeeBenefitEnrollments: [EmployeeBenefitEnrollment],
      employeeInsuranceNumbers: [EmployeeInsuranceNumber]
    });
  }

  @computed get statusTags() {
    const tags = [];

    if (this.terminated || this.onLeave) {
      tags.push(this.employmentStatus);
    }

    if ((this.onboardingStatus && !this.onboardingComplete) || this.onboardingInviteSkipped) {
      tags.push(this.onboardingStatus);
    }

    return tags;
  }

  @computed get active() {
    return this.employmentStatus === 'active';
  }

  @computed get terminated() {
    return this.employmentStatus === 'terminated';
  }

  @computed get onLeave() {
    return this.employmentStatus === 'on_leave';
  }

  @computed get draft() {
    return this.onboardingStatus === 'draft';
  }

  @computed get onboardingComplete() {
    return this.onboardingStatus === 'complete' || this.onboardingInviteSkipped;
  }

  @computed get onboardingInviteSkipped() {
    return this.onboardingStatus === 'invite_skipped';
  }

  @computed get name() {
    return fullName(this);
  }

  @computed get urlSlug() {
    return _.kebabCase(this.name);
  }

  @computed get hasTimeOff() {
    return !!this.timeOffPolicy;
  }

  @computed get firstNameDisplay() {
    return this.preferredName || this.firstName;
  }

  @computed get lastNameDisplay() {
    return this.preferredLastName || this.lastName;
  }

  @computed get employeePersonalCustomFields() {
    return _.filter(this.employeeCustomFields, {category: 'personal'});
  }

  @computed get employeeJobCustomFields() {
    return _.filter(this.employeeCustomFields, {category: 'job'});
  }
}

export default Employee;
