import {DomainStore} from 'shared/store';
import {action} from 'mobx';
import {endpoints, api} from 'shared/core';
import {successAlert} from 'shared/tools';

class SetupState {
  store = new DomainStore();

  @action async setup() {
    const {status} = await api.post(
      endpoints.NMBR.SETUP
    );

    if (status === 201) {
      successAlert('Nmbr company created!');
      window.location = '/nmbr/payroll';
    }
  }
}

export default SetupState;
