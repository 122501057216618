import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class NmbrPaySchedule extends DomainObject {
  @observable title;
  @observable payFrequency;
  @observable anchorPayDate;
  @observable anchorEndOfPayPeriod;
  @observable day1;
  @observable day2;
}

export default NmbrPaySchedule;
