import _ from 'lodash';
import {observable} from 'mobx';

class Steps {
  @observable furthestReachedStep;
  @observable enableStepSkipping;
  @observable activeStep = {index: -1};
  __allSteps;

  constructor(allSteps, options = {}) {
    this.__allSteps = allSteps;
    this.enableStepSkipping = options.enableStepSkipping;
  }

  all() {
    return this.__allSteps;
  }

  byIndex(index, traversingBack = false) {
    const step = this.__allSteps[index];

    if (step.noContent) {
      if (traversingBack) {
        return this.byIndex(index - 1, true);
      } else {
        return this.byIndex(index + 1);
      }
    } else {
      return step;
    }
  }

  first() {
    return _.head(this.__allSteps);
  }

  last() {
    return _.last(this.__allSteps);
  }

  before({location, subStepLocation}) {
    const index = this.stepIndex({location, subStepLocation});

    if (index <= 0) return null;

    return this.byIndex(index - 1, true);
  }

  after({location, subStepLocation}) {
    const index = this.stepIndex({location, subStepLocation});

    if (index === -1 || index === this.__allSteps.length - 1) return null;

    return this.byIndex(index + 1);
  }

  stepIndex({location, subStepLocation}) {
    const searchBy = {location};

    if (subStepLocation) {
      searchBy.subStepLocation = subStepLocation;
    }

    return _.findIndex(this.__allSteps, searchBy);
  }


  path(step) {
    if (step.noContent) {
      return this.path(this.after(step));
    } else if (step.subStepLocation) {
      return `${step.location}/${step.subStepLocation}`;
    } else {
      return step.location;
    }
  }

  nextStep() {
    return this.after(this.activeStep);
  }

  previousStep() {
    return this.before(this.activeStep);
  }

  isEnabled(step) {
    if (this.enableStepSkipping) {
      return this.stepIndex(step) <= this.furthestReachedStepIndex();
    }

    return this.stepIndex(step) <= this.stepIndex(this.activeStep);
  }

  enableNavigationToAllSteps() {
    this.furthestReachedStep = this.last();
  }

  furthestReachedStepIndex() {
    if (!this.furthestReachedStep) return -1;

    return this.stepIndex(this.furthestReachedStep);
  }

  setActiveStep(step) {
    this.activeStep = step;

    if (this.stepIndex(step) > this.furthestReachedStepIndex()) {
      this.furthestReachedStep = step;
    }
  }

  activeStepIsFirst() {
    return this.first().location === this.activeStep.location;
  }

  activeStepIsLast() {
    return this.last().location === this.activeStep.location;
  }
}

export default Steps;
