import React from 'react';
import {observer} from 'mobx-react';
import {Select2, FormError} from 'components';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';

const EnrolEmployeeModal = observer(({modalOpen, uiState}) => {
  const {paySchedules, selectedPayScheduleId, errors} = uiState;

  return (
    <Modal
      size='md'
      title={'nmbr.employees.directory.Enrol in nmbr'}
      isOpen={modalOpen}
      onHide={() => uiState.closeEnrolEmployeeModal()}
    >
      <FormattedMessage id='nmbr.employees.directory.Choose the pay schedule for this employee.'/>
      <Select2
        value={selectedPayScheduleId}
        onChange={e => uiState.updateSelectedPayScheduleId(e.target.value)}
        errorMessage={errors.nmbrPayScheduleId}
      >
        {paySchedules.map(paySchedule => <option value={paySchedule.id} key={paySchedule.title}>{paySchedule.title}</option>)}
      </Select2>
      <FormError message={errors.employee}/>
      <ModalButtons
        onSave={() => uiState.enrolEmployee()}
        saveCaption={'nmbr.employees.directory.Enrol'}
        onCancel={() => uiState.closeEnrolEmployeeModal()}
      />
    </Modal>
  );
});

export default EnrolEmployeeModal;
