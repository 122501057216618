import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import {NmbrPaySchedule} from 'stores/nmbr';

class NmbrEmployeesDirectoryState {
  interactiveAgent;
  store = new DomainStore();

  @observable paySchedules = [];

  @observable selectedEmployee;
  @observable selectedPayScheduleId;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.NMBR.PAY_SCHEDULES.ALL
    ]);

    this.paySchedules = this.store._getAll(
      types.NMBR.PAY_SCHEDULE,
      NmbrPaySchedule
    );
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action async enrolEmployee() {
    const {model, errors} = await this.store.post(
      endpoints.NMBR.EMPLOYEES.ENROL.with(this.selectedEmployee.id, this.selectedPayScheduleId),
      types.EMPLOYEE
    );

    this.errors = errors;
    if (model) {
      this.closeEnrolEmployeeModal();
      successAlert(t('nmbr.employees.directory.EMPLOYEE_ENROLLED', { name: this.selectedEmployee.name }));
      this.interactiveAgent.refresh();
    }
  }

  @action updateSelectedPayScheduleId(payScheduleId) {
    this.selectedPayScheduleId = payScheduleId;
  }

  @action async unenrolEmployee(employee) {
    const {model} = await this.store.post(
      endpoints.NMBR.EMPLOYEES.UNENROL.with(employee.id),
      types.EMPLOYEE
    );

    if (model) {
      successAlert(t('nmbr.employees.directory.EMPLOYEE_UNENROLLED', { name: this.selectedEmployee.name }));
    }
  }

  customLinksFor(employee) {
    return [{
      order: 0,
      text: employee.nmbrConfigured ? 'nmbr.employees.directory.Unenrol from nmbr' : 'nmbr.employees.directory.Enrol in nmbr',
      action: () => employee.nmbrConfigured ? this.unenrolEmployee(employee) : this.openEnrolEmployeeModal(employee)
    }];
  }
}

export default NmbrEmployeesDirectoryState;
