import React from 'react';
import {endpoints, types} from 'shared/core';
import {observer} from 'mobx-react';
import FilterComponent from './FilterComponent';
import useFetchModels from 'shared/hooks/useFetchModels';

const PtoPolicy = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const [models, isLoading] = useFetchModels(endpoints.TIME_OFF.POLICIES.CONFIGURED, types.TIME_OFF.POLICY);
  const options = models.map(policy => ({ label: policy.name, value: policy.id }));

  return <FilterComponent
    filter={filter}
    filterKey={filterKey}
    options={options}
    filterUpdated={filterUpdated}
    filterDeleted={filterDeleted}
    Wrapper={Wrapper}
    isLoading={isLoading}
    searchable
  />;
});

export default PtoPolicy;
