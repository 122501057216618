import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {Employee} from 'stores/employees';
import {calendarDate} from 'shared/tools';
import TimeOffTypePolicy from './TimeOffTypePolicy';
import _ from 'lodash';

class TimeOffPolicy extends DomainObject {
  @observable id;
  @observable name;
  @observable typePolicies;
  @observable currentYearStartDate;
  @observable currentYearEndDate;
  @observable nextYearStartDate;
  @observable workDayLength;
  @observable workWeekDays;
  @observable startDay;
  @observable startMonth;
  @observable employees;
  @observable completed;

  @action merge(other) {
    super.merge(other, {
      typePolicies: [TimeOffTypePolicy],
      employees: [Employee],
      _dates: ['currentYearStartDate']
    });
  }

  @computed get sortedTypePolicies() {
    return _.sortBy(this.typePolicies, 'type.order');
  }

  @computed get currentYearStartDateView() {
    return calendarDate(this.currentYearStartDate);
  }

  @computed get currentYearEndDateView() {
    return calendarDate(this.currentYearEndDate);
  }

  @computed get nextYearStartDateView() {
    return calendarDate(this.nextYearStartDate);
  }
}

export default TimeOffPolicy;
