import {observable, action, computed} from 'mobx';
import {Steps} from 'shared/tools';
import TimeOffPolicy from 'stores/time_off/TimeOffPolicy';
import TimeOffAccount from 'stores/time_off/TimeOffAccount';
import {auth, endpoints, types, t} from 'shared/core';
import {DomainStore} from 'shared/store';
import EmployeeDetails from 'stores/employees/EmployeeDetails';
import _ from 'lodash';
import moment from 'moment';
import {calendarDate, redirect} from 'shared/tools';

class ChangePolicyFlowState {
  store = new DomainStore();
  match;

  @observable employee;
  @observable errors = {};
  @observable oldPolicy;
  @observable type;
  @observable isLoading = false;
  @observable effectiveDate = new Date();
  @observable existingAccounts = [];
  @observable predictedAccounts = [];
  @observable effectiveDateOption;

  @action async load() {
    const employeeId = this.match.url.split('/')[1];
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(employeeId),
      endpoints.TIME_OFF.POLICY.withEmployeeId(employeeId),
      endpoints.TIME_OFF.POLICIES.CONFIGURED_DETAILS,
      endpoints.TIME_OFF.ACCOUNTS.with(employeeId)
    ]);

    const policies = this.store._getAll(
      types.TIME_OFF.POLICY,
      TimeOffPolicy
    );

    this.employee = new EmployeeDetails(this.store._getSingle(types.EMPLOYEE_DETAILS));
    this.oldPolicy = new TimeOffPolicy(this.employee.timeOffPolicy);
    this.policies = _.reject(policies, {id: this.employee.timeOffPolicy.id});
    this.employee.timeOffPolicy = null;
    this.existingAccounts = this.store._getAll(types.TIME_OFF.ACCOUNT).map(t => new TimeOffAccount(t));
  }

  receiveProps({match}) {
    this.match = match;
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'new-policy':
        return this.putNewPolicy();
      default:
        break;
    }
  }

  @action async putNewPolicy() {
    await this.store.put(
      endpoints.TIME_OFF.UPDATE_POLICY.with({
        employeeId: this.employee.id,
        effectiveDate: moment(this.effectiveDate).format('YYYY-MM-DD')
      }),
      types.EMPLOYEE_DETAILS,
      this.employee.pick(['timeOffPolicy'])
    );

    return redirect(`/employees/${this.employee.id}/time-off`);
  }

  @action async predictAccounts() {
    this.isLoading = true;
    const response = (await this.store._compose([
      endpoints.TIME_OFF.ACCOUNTS.PREDICT.with({
        employeeId: this.employee.id,
        policyId: this.newPolicy.id,
        effectiveDate: moment(this.effectiveDate).format('YYYY-MM-DD')
      })
    ]))[0];
    this.predictedAccounts.replace(response.data.map(t => new TimeOffAccount(t)));
    this.isLoading = false;
  }

  @action async setNewPolicy(value) {
    this.employee.timeOffPolicy = value;
    return this.updateEffectiveDateOption('oldPolicyStartYear');
  }

  @action async updateEffectiveDate(date) {
    this.effectiveDate = date;
    return this.predictAccounts();
  }

  @action async updateEffectiveDateOption(option) {
    this.effectiveDateOption = option;
    return this.updateEffectiveDate(this.calculateEffectiveDateFromOption());
  }

  calculateEffectiveDateFromOption() {
    switch (this.effectiveDateOption) {
      case 'oldPolicyStartYear':
        return this.oldPolicy.currentYearStartDate;
      case 'newPolicyStartYear':
        return this.newPolicy.currentYearStartDate;
      case 'employeeStartDate':
        return this.employee.startDate;
      case 'nextAccrual':
        return new Date();
      default:
        throw new Error(`Unsupported option ${this.effectiveDateOption}`);
    }
  }

  @computed get typePolicies() {
    return [...this.oldPolicy.typePolicies, ..._.get(this, 'newPolicy.typePolicies', [])];
  }

  @computed get types() {
    return _.chain(this.typePolicies)
      .uniqBy('type.id')
      .map('type')
      .value();
  }

  @computed get sortedTypes() {
    return _.sortBy(this.types, 'order');
  }

  @computed get steps() {
    return new Steps([
      {
        name: 'employees.profile.time_off.Choose New Policy',
        location: 'new-policy',
        index: 1
      }
    ]);
  }

  @computed get newPolicy() {
    return this.employee.timeOffPolicy;
  }

  @computed get predictedAccountRows() {
    return this.sortedTypes.map(t => ({
      id: t.id,
      name: t.name,
      oldValue: this.balanceForType(t.id, this.existingAccounts),
      newValue: this.balanceForType(t.id, this.predictedAccounts)
    }));
  }

  balanceForType(typeId, accounts) {
    const account = _.find(accounts, a => a.timeOffType.id === typeId);
    if (!account) return t('employees.profile.time_off.N/A');
    if (account.unlimited) return auth.featureEnabled(':flexible_time_off') ? t('employees.profile.time_off.Flexible') : t('employees.profile.time_off.Unlimited');

    return account.balance;
  }

  @computed get predictedAccountColumns() {
    return [
      {
        header: 'employees.profile.time_off.Time Off Type',
        width: 4,
        attribute: 'name'
      },
      {
        attribute: 'oldValue',
        header: t('employees.profile.time_off.OLD_POLICY', {policyName: this.oldPolicy.name}),
        width: 4
      },
      {
        attribute: 'newValue',
        header: t('employees.profile.time_off.NEW_POLICY', {policyName: this.newPolicy.name}),
        width: 4
      }
    ];
  }

  @computed get effectiveDateExplainer() {
    const date = calendarDate(this.effectiveDate);
    const employeeName = this.employee.name;
    const policyName = this.newPolicy.name;

    switch (this.effectiveDateOption) {
      case 'oldPolicyStartYear':
      case 'newPolicyStartYear':
      case 'employeeStartDate':
        return {
          header: t('employees.profile.time_off.effective_date_explainers.retroactive.HEADER', {date}),
          paragraphs: [
            t('employees.profile.time_off.effective_date_explainers.retroactive.EXPLAINER_1', {date, employeeName}),
            t('employees.profile.time_off.effective_date_explainers.retroactive.EXPLAINER_2', {policyName}),
            t('employees.profile.time_off.effective_date_explainers.retroactive.EXPLAINER_3')
          ]
        };
      case 'nextAccrual':
        return {
          header: t('employees.profile.time_off.effective_date_explainers.next_accrual.HEADER'),
          paragraphs: [
            t('employees.profile.time_off.effective_date_explainers.next_accrual.EXPLAINER_1', {employeeName}),
            t('employees.profile.time_off.effective_date_explainers.next_accrual.EXPLAINER_2', {policyName}),
            t('employees.profile.time_off.effective_date_explainers.next_accrual.EXPLAINER_3')
          ]
        };
      default:
        return null;
    }
  }

  @computed get effectiveDateOptions() {
    const startYearOptions = [];
    if (this.oldPolicy.currentYearStartDate.getTime() === this.newPolicy.currentYearStartDate.getTime()) {
      startYearOptions.push({
        name: t('employees.profile.time_off.POLICY_YEAR_START', {date: calendarDate(this.oldPolicy.currentYearStartDate)}),
        value: 'oldPolicyStartYear'
      });
    } else {
      startYearOptions.push({
        name: t('employees.profile.time_off.OLD_POLICY_YEAR_START', {policyName: this.oldPolicy.name, date: calendarDate(this.oldPolicy.currentYearStartDate)}),
        value: 'oldPolicyStartYear'
      });
      startYearOptions.push({
        name: t('employees.profile.time_off.NEW_POLICY_YEAR_START', {policyName: this.newPolicy.name, date: calendarDate(this.newPolicy.currentYearStartDate)}),
        value: 'newPolicyStartYear'
      });
    }

    return [
      {
        name: t('employees.profile.time_off.EMPLOYEE_START_DATE', {employeeName: this.employee.name, date: calendarDate(this.employee.startDate)}),
        value: 'employeeStartDate'
      },
      ...startYearOptions,
      {
        name: t('employees.profile.time_off.with_the_next_accrual'),
        value: 'nextAccrual'
      }
    ];
  }

  @computed get nextEnabled() {
    return !!this.newPolicy && !this.isLoading;
  }
}

export default ChangePolicyFlowState;
