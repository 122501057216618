import {observable, action, computed} from 'mobx';
import _ from 'lodash';

class FlowState {
  history;
  localization = {};

  @observable match;
  @observable deleteModalOpen = false;
  @observable steps;
  @observable onStepSubmitted;
  @observable onDeleted;
  @observable onStepChanged;
  @observable nextEnabled = true;

  constructor({history}) {
    this.history = history;
  }

  @action updateProps({localization, steps, onStepSubmitted, onDeleted, onStepChanged, nextEnabled, match}) {
    this.steps = steps;
    this.onStepSubmitted = onStepSubmitted;
    this.onDeleted = onDeleted;
    this.onStepChanged = onStepChanged;
    this.nextEnabled = nextEnabled;
    this.match = match;

    _.merge(this.localization, localization);
  }

  @action async submitStep() {
    const nextStep = this.steps.nextStep();

    const nextLocation = await this.__continue();
    if (nextLocation === false || !nextStep) return;

    const nextPath = _.get(nextLocation, 'nextLocation');

    this.history.push(nextPath || `${this.match.url}/${this.steps.path(nextStep)}`);
  }

  @action goBack() {
    this.history.push(`${this.match.url}/${this.steps.path(this.steps.previousStep())}`);
  }

  @action async __continue() {
    if (!this.onStepSubmitted) return true;

    return this.onStepSubmitted(this.steps.activeStep);
  }

  @action async setCurrentStep(step) {
    this.steps.setActiveStep(step);

    if (!this.onStepChanged) return null;

    return this.onStepChanged(step);
  }

  @action async openDeleteModal() {
    this.deleteModalOpen = true;
  }

  @action closeDeleteModal() {
    this.deleteModalOpen = false;
  }

  @action async confirmDeletion() {
    return this.onDeleted();
  }

  @computed get backEnabled() {
    return !this.steps.activeStepIsFirst();
  }

  @computed get deleteEnabled() {
    return !!this.onDeleted;
  }

  @computed get isFinalStep() {
    return this.steps.activeStepIsLast();
  }
}

export default FlowState;
