import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {auth, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Label} from 'components';

const DetailRow = observer(({title, value, className = 'jumbo'}) => {
  return (
    <div className='clearfix my2'>
      <div className='col col-6'>
        {title}
      </div>
      <div className={`col col-6 ${className}`}>
        <Label model={value} />
      </div>
    </div>
  );
});

const PolicySummaryModal = observer(({uiState}) => {
  const {policySummaryAccount} = uiState;
  if (!_.get(policySummaryAccount, 'timeOffType')) return null;
  const {currentTypePolicy} = uiState;
  const {timeOffType} = policySummaryAccount;

  return (
    <Modal isOpen={uiState.unlimitedPolicySummaryModalOpen} onHide={() => uiState.closePolicySummaryModals()} size='md'>
      <div>
        <div className='h2 medium'>
          <FormattedMessage id='employees.profile.time_off.POLICY' values={{policy: timeOffType.name}}/>
        </div>
        <DetailRow 
          title={t('employees.profile.time_off.Total Days/Year')} className='black medium' 
          value={policySummaryAccount.unlimited && auth.featureEnabled(':flexible_time_off') ?
            t('employees.profile.time_off.Flexible') :
            t('employees.profile.time_off.DAYS', {days: policySummaryAccount.balanceView})
          }
        />
        <DetailRow 
          title={t('employees.profile.time_off.Waiting Period')} className='black medium' 
          value={t('employees.profile.time_off.DAYS', {days: currentTypePolicy.waitPeriod})}/>
        <hr/>
        <div className='h3 medium mb2'>
          <FormattedMessage id='employees.profile.time_off.Additional Details'/>
        </div>
        <FormattedMessage id='employees.profile.time_off.This policy does not accrue a balance, but you can still request time off.'/>
      </div>
    </Modal>
  );
});

export default PolicySummaryModal;

