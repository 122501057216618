const initialSteps = [
  {
    name: 'time_off.policy.edit.steps.Policy Details',
    location: 'details'
  },
  {
    name: 'time_off.policy.edit.steps.Time Off Types',
    location: 'policy_types'
  },
  {
    name: 'time_off.policy.edit.steps.Time Off Rules',
    location: 'time_off_rules',
    noContent: true
  }
];

const finalSteps = [
  {
    name: 'time_off.policy.edit.steps.Add Employees',
    location: 'employees'
  },
  {
    name: 'time_off.policy.edit.steps.Review',
    location: 'review'
  }
];

export { initialSteps, finalSteps };
