import React from 'react';
import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {auth, endpoints, types, api, t} from 'shared/core';
import {dateToJson, successAlert} from 'shared/tools';
import {TimeOffRequest, ForecastedAccountBalance} from 'stores/time_off';
import moment from 'moment';
import * as queryString from 'querystring';
import _ from 'lodash';
import DayOffViewModel from 'stores/time_off/DayOffViewModel';
import AssignmentsList from '../components/AssignmentsList';

const MAX_DAYS_OFF_ITEMS = 3;

class PendingRequestDetailsState {
  store = new DomainStore();
  history;
  match;

  @observable request;
  @observable existingBalance = {};
  @observable forecastedBalance = {};
  @observable daysOffJson = [];
  @observable concurrentRequests = []
  @observable expanded = false;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.loadPendingRequest();
    await Promise.all([
      this.fetchRequestedDays(),
      this.forecastAccount(),
      this.fetchConcurrentRequests()
    ]);
  }

  @action async loadPendingRequest() {
    const params = queryString.parse(this.history.location.search.replace('?', ''));

    try {
      await this.store._compose([
        endpoints.TIME_OFF.REQUEST.with(this.match.params.id)
      ]);

      this.request = new TimeOffRequest(this.store._getSingle(
        types.TIME_OFF.REQUEST, { id: this.match.params.id }
      ));

      if ((!!params.approval_stage && params.approval_stage !== this.request.currentApprovalStage) || this.request.status !== 'pending') {
        successAlert(t('time_off.requests.Request has already been actioned.'));
        this.history.push('/pending_requests');
      }
    } catch(e) {
      successAlert(t('time_off.requests.Request is no longer available.'));
      this.history.push('/pending_requests');
    }
  }

  @action async fetchConcurrentRequests() {
    if (!this.request) return;

    const response = await this.store._compose([
      endpoints.TIME_OFF.CONCURRENT.with({
        employeId: this.request.employee.id,
        id: this.request.id
      })
    ]);

    this.concurrentRequests = response[0].data;
  }

  @action async fetchRequestedDays() {
    if (!this.request) return;

    const response = await api.get(
      endpoints.TIME_OFF.DAYS_OFF.FOR_REQUEST.with({
        employeeId: this.request.employee.id,
        id: this.request.id,
      })
    );

    this.daysOffJson = response.data;

    this.daysOffJson.forEach(day => {
      if (day.start_time) {
        day.start_time = moment(day.start_time).format(t('components.dates.TIMESTAMP_FORMAT'));
        day.end_time = moment(day.end_time).format(t('components.dates.TIMESTAMP_FORMAT'));
      }
    });
  }

  @action async forecastAccount() {
    if (!this.request) return;

    const {model, errors} = await this.store.post(
      endpoints.TIME_OFF.FORECAST_ACCOUNT_BALANCE.FOR_REQUEST.with({
        employeeId: this.request.employee.id,
        typeId: this.request.timeOffType.id,
        date: dateToJson(this.request.startDate),
        id: this.request.id
      }),
      types.TIME_OFF.REQUEST_FORECAST
    );
    this.errors = errors;

    if (model) {
      this.existingBalance = new ForecastedAccountBalance({
        ...model.balanceBefore,
        workDayLength: model.workDayLength,
        unlimited: model.unlimited
      });

      this.forecastedBalance = new ForecastedAccountBalance({
        ...model.balanceAfter,
        workDayLength: model.workDayLength,
        unlimited: model.unlimited
      });
    }
  }

  @action async approveRequest() {
    await this.store.patch(
      this.request.link('approve'),
      types.TIME_OFF.REQUEST,
      this.request
    );

    successAlert(t('time_off.requests.Request has been approved'));
    this.goBack();
  }

  @action async rejectRequest() {
    await this.store.patch(
      this.request.link('reject'),
      types.TIME_OFF.REQUEST,
      this.request
    );

    successAlert(t('time_off.requests.Request has been rejected'));
    this.goBack();
  }

  @action goBack() {
    this.history.push('/pending_requests');
  }

  @computed get daysOff() {
    return this.daysOffJson.map(json => new DayOffViewModel(json, false));
  }

  @computed get totalCalendarDays() {
    return moment(this.request.endDate).diff(this.request.startDate, 'days') + 1;
  }

  @computed get totalNonWorkDays() {
    return _.filter(this.daysOff, 'hasCompanyDayOff').length;
  }

  @computed get includedHolidays() {
    return _.filter(this.daysOff, 'hasHoliday').map(d => d.holidayName);
  }

  @computed get usageItems() {
    if (!this.forecastedBalance || !this.existingBalance) {
      return [];
    }

    return [
      {
        type : t('employees.profile.time_off.request.forecast.Available'),
        before: this.existingBalance.unlimited ?
                  (auth.featureEnabled(':flexible_time_off') ? t('employees.profile.time_off.request.forecast.Flexible') : t('employees.profile.time_off.request.forecast.Unlimited')) :
                  t('employees.profile.time_off.request.forecast.DAYS', {days: this.existingBalance.availableDays}),
        after: this.forecastedBalance.unlimited ?
                 (auth.featureEnabled(':flexible_time_off') ? t('employees.profile.time_off.request.forecast.Flexible') : t('employees.profile.time_off.request.forecast.Unlimited')) :
                 t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedBalance.availableDays}),
        before_is_negative: !this.existingBalance.unlimited && this.existingBalance.availableDays < 0,
        after_is_negative: !this.forecastedBalance.unlimited && this.forecastedBalance.availableDays < 0
      },
      {
        type: t('employees.profile.time_off.request.forecast.Used'),
        before: t('employees.profile.time_off.request.forecast.DAYS', {days: this.existingBalance.usedDays}),
        after: t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedBalance.usedDays}),
        before_is_negative: false,
        after_is_negative: false
      },
      {
        type : t('employees.profile.time_off.request.forecast.Scheduled'),
        before: t('employees.profile.time_off.request.forecast.DAYS', {days: this.existingBalance.scheduledDays}),
        after: t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedBalance.scheduledDays}),
        before_is_negative: false,
        after_is_negative: false
      }
    ];
  }

  @computed get summaryItems() {
    let items = [
      {
        title: t('employees.profile.time_off.request.summary.Duration'),
        value: t('employees.profile.time_off.request.summary.CALENDAR_DAYS', {days: this.totalCalendarDays})
      }
    ];
    if (this.totalNonWorkDays) {
      items.push({
        title: t('employees.profile.time_off.request.summary.Included Non-work Days'),
        value: t('employees.profile.time_off.request.summary.COST_IN_DAYS', {days: this.totalNonWorkDays})
      });
    }
    if (this.includedHolidays.length) {
      items.push({
        title: t('employees.profile.time_off.request.summary.Included Holidays'),
        value: this.includedHolidays.join(', ')
      });
    }
    items.push({
      title: t('employees.profile.time_off.request.summary.Total Cost'),
      value: t('employees.profile.time_off.request.summary.COST_IN_DAYS', {days: this.request.requestedDaysOff})
    });

    if (!_.isEmpty(this.request.firstStageStatus) && !_.isEmpty(this.request.secondStageStatus)) {
      const currentStage = this.request.firstStageStatus === 'approved' ? '2' : '1';
      items.push({
        title: t('time_off.requests.Current Stage'),
        value: t('time_off.requests.APPROVAL_STAGE', { currentStage })
      });
    }

    if (this.request.firstStageActionedByUser) {
      items.push({
        title: t('time_off.requests.First Stage Approved by'),
        value: this.request.firstStageActionedByUser.name
      });
    }

    if (this.request.currentlyAssignedUsers.length > 0) {
      items.push({
        title: t('time_off.requests.Currently Assigned to'),
        value: <AssignmentsList assignments={this.request.currentAssignments}/>
      });
    }

    return items;
  }

  @computed get showNegativeBalanceWarning() {
    return !this.forecastedBalance.unlimited && this.forecastedBalance.availableDays < 0;
  }

  @computed get expandedText() {
    return this.expanded ?
      t('time_off.requests.SHOW_LESS') :
      t('time_off.requests.SHOW_MORE', {num: this.daysOff.length - MAX_DAYS_OFF_ITEMS});
  }
}

export default PendingRequestDetailsState;
