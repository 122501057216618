import React from 'react';
import {observer} from 'mobx-react';
import {t, endpoints} from 'shared/core';
import {Panel, EmployeeTableSelector} from 'components';

const Employees = observer(({uiState}) => {
  const {policy, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_tracking.manage.policies.edit.steps.Employees')}
        description={t('time_tracking.manage.policies.edit.Select the employees that will be included in this policy.')}
      />
      <EmployeeTableSelector
        includedEmployees={uiState.selectedEmployees}
        endpoint={endpoints.TIME_TRACKING.POLICIES.ELIGIBLE_EMPLOYEES.with(policy.id)}
        onChange={employees => uiState.updateSelectedEmployees(employees)}
        errorMessage={errors.employees}
      />
    </div>
  );
});

export default Employees;
