import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, CheckboxWithLabel, FormError} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const EmptyState = () => {
  return (
    <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
      <FormattedMessage id={'time_off.policy.edit.No time off types to display'}/>
    </div>
  );
};

const TimeOffTypes = observer(({uiState}) => {
  const {sortedTimeOffTypes, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_off.policy.edit.Time Off Types')}
        description={t('time_off.policy.edit.Select the types of time off you would like made available to employees under this policy.')}
      />
      {_.isEmpty(sortedTimeOffTypes) && <EmptyState/>}
      {sortedTimeOffTypes.map((type, index) => <div className={`flex align-items-middle px1 py2 ${index === (sortedTimeOffTypes.length - 1) ? '' : 'border-bottom'}`}>
        <CheckboxWithLabel
          checked={uiState.timeOffTypeSelected(type)}
          onChange={() => uiState.toggleTimeOffType(type)}
          label={type.name}
        />
      </div>)}
      <FormError message={errors.typeIds}/>
    </div>
  );
});

export default TimeOffTypes;
