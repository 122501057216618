import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {auth, withState} from 'shared/core';
import NmbrPageState from '../state/NmbrPageState';
import NmbrPayrollContainer from './NmbrPayrollContainer';
import {NmbrEmployeesDirectory} from './employees';

const NmbrPage = observer(({uiState}) => {
  if (!auth.featureEnabled(':reactify_time_off_policies')) { return null; }

  return (
    <Router basename='/nmbr'>
      <Switch>
        <Route path='/payroll' render={() => <NmbrPayrollContainer/>}/>
        <Route path='/employees' render={() => <NmbrEmployeesDirectory/>}/>
        <Redirect exact from='/' to='/payroll'/>
      </Switch>
    </Router>
  );
});

export default withState(NmbrPage, NmbrPageState);
