import React from 'react';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import AccrualForm from './AccrualForm';
import CarryoverForm from './CarryoverForm';
import WaitingPeriodForm from './WaitingPeriodForm';
import ApprovalForm from './ApprovalForm';
import {Panel, RadioButtonGroup, PanelRadioButton} from 'components';
import PolicyConfirmationModal from '../PolicyConfirmationModal';
import useModal from 'shared/hooks/useModal';

const TimeOffRules = observer(({uiState, submitStep}) => {
  const policyConfirmationModalOpen = useModal(uiState, 'PolicyConfirmationModal');
  const {policy, typePolicy, accrualType} = uiState;

  return (
    <div>
      <Panel.Stack loose>
        <Panel>
          <Panel.Header title={t('time_off.policy.edit.ACCRUAL_SETTINGS', {type: typePolicy.type.name})}/>
          <RadioButtonGroup
            className={'clearfix table'}
            name='accrualType'
            selectedValue={accrualType}
            onChange={(name, value) => uiState.selectAccrualType(value)}
          >
            <PanelRadioButton
              value='standard'
              label='time_off.policy.edit.Standard'
              description='time_off.policy.edit.STANDARD_EXPLANATION'
              onClick={() => uiState.accrualType = 'standard'}
              isActive={accrualType === 'standard'}
            >
              <AccrualForm uiState={uiState}/>
              <CarryoverForm uiState={uiState}/>
            </PanelRadioButton>
            <PanelRadioButton
              value='unlimited'
              label={auth.featureEnabled(':flexible_time_off') ? 'time_off.policy.edit.Flexible' : 'time_off.policy.edit.Unlimited'}
              description={auth.featureEnabled(':flexible_time_off') ? 'time_off.policy.edit.FLEXIBLE_EXPLANATION' : 'time_off.policy.edit.UNLIMITED_EXPLANATION'}
              onClick={() => uiState.accrualType = 'unlimited'}
              isActive={accrualType === 'unlimited'}
            >
            </PanelRadioButton>
          </RadioButtonGroup>
        </Panel>
        <WaitingPeriodForm uiState={uiState}/>
        <ApprovalForm uiState={uiState}/>
      </Panel.Stack>
      <PolicyConfirmationModal
        uiState={uiState}
        description="time_off.policy.edit.Choose when you'd like the changes to take effect and preview how they will impact employee balances."
        isOpen={policyConfirmationModalOpen}
        employees={policy.employees}
        onSave={submitStep}
        updateForecast={() => uiState.forecastTypePolicy()}
      />
    </div>
  );
});

export default TimeOffRules;
