import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {auth, endpoints, types, t} from 'shared/core';
import {calendarDate, Steps} from 'shared/tools';
import {TimeOffPolicy, TimeOffTypePolicy, TimeOffType, BalanceAdjustmentPolicy, TypePolicyConfirmationSummary, AnniversaryBonus} from 'stores/time_off';
import {initialSteps, finalSteps} from './steps';
import {User} from 'stores/users';
import _ from 'lodash';
import moment from 'moment';

class PolicyEditFlowState {
  store = new DomainStore();
  history;
  match;

  @observable policy;
  @observable oldTypePolicy;
  @observable typePolicy;
  @observable balanceAdjustmentPolicy;
  @observable accrualType = 'standard';
  @observable carryoverType;
  @observable assignee = {};
  @observable availableAssignees;
  @observable waitPeriodEnabled = false;
  @observable confirmationSummary;
  @observable steps;
  @observable errors = {};

  @observable effectiveDateType;
  @observable forecastingEmployeeId;
  @observable forecastDate;
  @observable forecastedValues;
  @observable isForecastingEmployee = false;
  @observable policyConfirmationModalOpen = false;

  @observable hoursPerWorkday;
  @observable workDayLengthChanged = false;
  @observable startMonthDate;

  @observable timeOffTypes = [];
  @observable selectedTimeOffTypes = [];

  @observable availableEmployees = [];
  @observable selectedEmployees = [];
  @observable welcomeEmailPreview;

  get typePolicyProps() {
    let props = [
      'accrualFrequency',
      'baseAccrualRate',
      'accrualStart',
      'accrueUpfront',
      'prorationEnabled',
      'approvalType',
      'anniversaryBonuses',
      'balanceAdjustmentPolicy',
      'secondStageApprovalType',
      'secondStageApprovalAssigneeUser'
    ];
    if (this.waitPeriodEnabled) props.push('waitPeriod');
    if (this.usesAccrualStartDate) props.push('accrualStartDate');
    return props;
  }

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.POLICY.with(this.match.params.id),
      endpoints.USERS.ACTIVE,
      endpoints.TIME_OFF.TYPES.ALL
    ]);

    this.policy = new TimeOffPolicy(this.store._getSingle(types.TIME_OFF.POLICY));
    this.timeOffTypes = this.store._getAll(types.TIME_OFF.TYPE, TimeOffType);
    this.hoursPerWorkday = this.policy.workDayLength / 3600;
    this.startMonthDate = new Date(new Date().getFullYear(), 6, 1);
    this._initializeSteps();
  }

  @action async onStepChanged({location, subStepLocation}) {
    switch (location) {
      case 'policy_types':
        await this.initializeTimeOffTypes();
        break;
      case 'employees':
        await this.initializeEmployees();
        break;
      case 'time_off_rules':
        this.initializeTypePolicy(subStepLocation);
        break;
      case 'review':
        await this.initializeEmailTemplate();
        break;
      default:
        break;
    }
  }

  @action async initializeTimeOffTypes() {
    this.selectedTimeOffTypes = this.store._getAll(
      types.TIME_OFF.TYPE,
      type => _.map(this.policy.typePolicies, 'type.id').includes(type.id),
      TimeOffType
    );
  }

  @action async initializeEmployees() {
    this.selectedEmployees = this.policy.employees.toJS();
  }

  @action initializeTypePolicy(typePolicyId) {
    this.typePolicy = new TimeOffTypePolicy(
      this.store._getSingle(types.TIME_OFF.TYPE_POLICY, {id: typePolicyId})
    );
    this.typePolicy.anniversaryBonuses = this.typePolicy.sortedAnniversaryBonuses;
    this.oldTypePolicy = new TimeOffTypePolicy(this.typePolicy);

    this.initializeAccrualFrequency();
    this.initializeBalanceAdjustmentPolicy();
    this.initializeWaitingPeriod();
    this.initializeAssignees();
  }

  @action initializeAccrualFrequency() {
    if (this.typePolicy.accrualFrequency === 'unlimited') {
      this.accrualType = 'unlimited';
      this.typePolicy.accrualStartDate = null;
    } else {
      this.accrualType = 'standard';
    }
  }

  @action initializeWaitingPeriod() {
    this.waitPeriodEnabled = this.typePolicy.waitPeriod > 0;
    this.typePolicy.waitPeriod = this.waitPeriodEnabled ? this.typePolicy.waitPeriod : null;
  }

  @action initializeBalanceAdjustmentPolicy() {
    if (this.typePolicy.accrualFrequency === 'unlimited') {
      return;
    }

    if (!this.typePolicy.balanceAdjustmentPolicy ) {
      this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'AnnualResetPolicy'});
    }
    const {balanceAdjustmentPolicy} = this.typePolicy;

    switch (balanceAdjustmentPolicy.type) {
      case 'AnnualResetPolicy':
        this.carryoverType = 'annual_reset';
        break;
      case 'NoLimitPolicy':
        this.carryoverType = 'no_limit';
        break;
      case 'BalanceLimitPolicy':
        this.carryoverType = 'balance_limit';
        break;
      case 'CarryoverPolicy':
        this.carryoverType = balanceAdjustmentPolicy.limitDays ? 'capped_with_deadline' : 'capped';
        break;
      default:
        throw new Error(`Balance adjustment type ${balanceAdjustmentPolicy.type} is not supported`);
    }
  }

  @action initializeAssignees() {
    const users = this.store._getAll(types.USER).map(u => new User(u));
    this.availableAssignees = [
      {
        id: 'manager', type: 'manager', name: t('time_off.policy.edit.Manager')
      },
      {
        id: 'managers_manager', type: 'managers_manager', name: t("time_off.policy.edit.Manager's manager")
      },
      ...users.map(u => {
        return { id: u.id, type: 'user', user: u, name: u.name };
      })
    ];

    if (this.typePolicy.secondStageApprovalType === 'manager') {
      this.assignee = _.find(this.availableAssignees, a => a.type === 'manager');
    } else if (this.typePolicy.secondStageApprovalType === 'managers_manager') {
      this.assignee = _.find(this.availableAssignees, a => a.type === 'managers_manager');
    } else if (this.typePolicy.secondStageApprovalType === 'user') {
      this.assignee = _.find(this.availableAssignees, a => a.id === this.typePolicy.secondStageApprovalAssigneeUser.id);
    }
  }

  @action async initializeEmailTemplate() {
    await this.store._compose([
      endpoints.COMPANY_EMAIL_TEMPLATES
    ]);

    this.welcomeEmailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'pto_policy_invitation'});
  }

  @action updateHoursPerWorkday(value) {
    this.hoursPerWorkday = value;
    this.policy.workDayLength = value * 3600;
    this.workDayLengthChanged = true;
  }

  @action toggleWorkWeekDay(day) {
    if (this.workdaySelected(day)) {
      _.pull(this.policy.workWeekDays, day);
    } else {
      this.policy.workWeekDays.push(day);
    }
  }

  @action toggleTimeOffType(type) {
    if (this.timeOffTypeSelected(type)) {
      _.remove(this.selectedTimeOffTypes, t => t.id === type.id);
    } else {
      this.selectedTimeOffTypes.push(type);
    }
  }

  @action updateSelectedEmployees(employees) {
    this.selectedEmployees.replace(employees);
  }

  @action openPolicyConfirmationModal() {
    this.policyConfirmationModalOpen = true;

    this.effectiveDateType = this.effectiveDateType || this.effectiveDateOptions[1].type;
    this.forecastDate = this.forecastDate || this.forecastDateOptions[0].date;
  }

  @action closePolicyConfirmationModal() {
    this.policyConfirmationModalOpen = false;
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'details': return this.submitDetails();
      case 'policy_types': return this.submitTimeOffTypes();
      case 'employees': return this.employeeStepSubmitted();
      case 'time_off_rules': return this.typeRulesStepSubmitted();
      case 'review': return this.submitReview();
      default:
        throw new Error(`Location ${location} is not supported`);
    }
  }

  @action async employeeStepSubmitted() {
    if (_.isEmpty(this.newSelectedEmployees)) {
      return this.submitEmployees();
    }
    if (this.policyConfirmationModalOpen) {
      return this.submitEmployees();
    }

    this.openPolicyConfirmationModal();
    this.updateForecastingEmployee(this.newSelectedEmployees[0].id, () => this.forecastPolicy());

    return false;
  }

  @action async submitDetails() {
    this.workDayLengthChanged = false;

    return this._submitStep(
      ['name', 'workDayLength', 'startMonth', 'startDay', 'workWeekDays'],
      endpoints.TIME_OFF.POLICY.UPDATE_DETAILS.with(this.policy.id)
    );
  }

  @action async submitReview() {
    const submit = await this._submitStep(
      ['sendEmployeeWelcomeNotifications'],
      endpoints.TIME_OFF.POLICY.COMPLETE.with(this.policy.id)
    );
    if (submit) {
      this.history.push('/policies');
    }
  }

  @action async submitTimeOffTypes() {
    this.policy.types = this.selectedTimeOffTypes;

    return this._submitStep(
      ['types'],
      endpoints.TIME_OFF.POLICY.UPDATE_TYPES.with(this.policy.id)
    );
  }

  @action async submitEmployees() {
    this.policy.employees = this.selectedEmployees;
    this.policy.effectiveDate = this.effectiveDate;

    const submitSuccessful = await this._submitStep(
      ['employees', 'effectiveDate'],
      endpoints.TIME_OFF.POLICY.UPDATE_EMPLOYEES.with(this.policy.id)
    );

    if (submitSuccessful) {
      this.closePolicyConfirmationModal();
      this.forecastingEmployeeId = null;
    }

    return submitSuccessful;
  }

  @action async typeRulesStepSubmitted() {
    if (this.accrualType === 'unlimited') this.clearTypePolicy();
    if (_.isEmpty(this.policy.employees)) {
      return this.submitTypePolicy();
    }

    const {model, errors} = await this.store.post(
      endpoints.TIME_OFF.TYPE_POLICIES.UPDATE_CONFIRMATION_SUMMARY.with(this.typePolicy.id),
      types.TIME_OFF.TYPE_POLICY_CONFIRMATION_SUMMARY,
      this.typePolicy.pick(this.typePolicyProps)
    );

    if (!model) {
      this.errors = errors;
      return false;
    }
    this.confirmationSummary = new TypePolicyConfirmationSummary(model);

    if (this.confirmationSummary.effectiveDateRequired && !this.policyConfirmationModalOpen) {
      this.openPolicyConfirmationModal();
      this.errors = {};
      const employeeId = _.includes(_.map(this.policy.employees, 'id'), this.forecastingEmployeeId) ? this.forecastingEmployeeId : this.policy.employees[0].id;
      this.updateForecastingEmployee(employeeId, () => this.forecastTypePolicy());
      return false;
    }

    const submitSuccessful = this.submitTypePolicy();

    if (submitSuccessful) {
      this.closePolicyConfirmationModal();
      this.confirmationSummary = null;
      this.forecastingEmployeeId = null;
      this.effectiveDateType = null;
      this.forecastDate = null;
    }

    return submitSuccessful;
  }

  @action async submitTypePolicy() {
    const typePolicy = this.typePolicy.pick(this.typePolicyProps);
    if (this.effectiveDate) {
      typePolicy.newEffectiveDate = this.effectiveDate;
    }
    const {model, errors} = await this.store.patch(typePolicy);

    this.errors = errors;
    if (!model) return false;

    this.typePolicy.update(model);
    return true;
  }

  @action async _submitStep(props, endpoint) {
    const policy = this.policy.pick(props);
    const {model, errors} = await this.store.patch(endpoint, types.TIME_OFF.POLICY, policy);

    this.errors = errors;
    if (!model) return false;

    this.policy.update(model);
    this._initializeSteps();

    if (!this.steps.nextStep()) return true;

    return {
      nextLocation: `${this.steps.path(this.steps.nextStep())}`
    };
  }

  @action selectAssignee(assignee) {
    this.typePolicy.secondStageApprovalType = assignee.type;
    this.typePolicy.secondStageApprovalAssigneeUser = assignee.user;
    this.assignee = assignee;
  }

  @action selectAccrualType(accrualType) {
    switch(accrualType) {
      case 'standard':
        this.accrualType = accrualType;
        if (this.typePolicy.accrualFrequency === 'unlimited') this.resetTypePolicy();
        break;
      case 'unlimited':
        this.accrualType = accrualType;
        break;
      default:
        throw new Error(`Accrual type ${accrualType} is not supported`);
    }
  }

  @action clearTypePolicy(){
    this.typePolicy.accrualFrequency = 'unlimited';
    this.typePolicy.baseAccrualRate = null;
    this.typePolicy.accrualStartDate = null;
    this.typePolicy.accrueUpfront = true;
    this.typePolicy.balanceAdjustmentPolicy = null;
    this.typePolicy.anniversaryBonuses = [];
  }

  @action resetTypePolicy(){
    this.typePolicy.accrualFrequency = 'annual';
    this.initializeBalanceAdjustmentPolicy();
    this.typePolicy.baseAccrualRate = '';
    this.typePolicy.accrualStartDate = moment().toDate();
    this.typePolicy.anniversaryBonuses = [];
  }

  @action selectCarryoverType(carryoverType) {
    // TODO: Ensure we throw an error if you set capped with deadline but don't set a limit
    switch(carryoverType) {
      case 'annual_reset':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'AnnualResetPolicy'});
        break;
      case 'no_limit':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'NoLimitPolicy'});
        break;
      case 'balance_limit':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'BalanceLimitPolicy', parameter: ''});
        break;
      case 'capped':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'CarryoverPolicy', parameter: ''});
        break;
      case 'capped_with_deadline':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'CarryoverPolicy', parameter: '', limitDays: ''});
        break;
      default:
        throw new Error(`Carryover type ${carryoverType} is not supported`);
    }
    this.carryoverType = carryoverType;
  }

  @action toggleWaitingPeriod(waitPeriodEnabled) {
    this.waitPeriodEnabled = waitPeriodEnabled;
    this.typePolicy.waitPeriod = waitPeriodEnabled ? '' : null;
  }

  @action addAnniversaryBonus() {
    const newBonus = new AnniversaryBonus({anniversary: '', effectiveRate: ''});
    this.typePolicy.anniversaryBonuses.push(newBonus);
  }

  @action removeAnniversaryBonus(anniversaryBonus) {
    this.typePolicy.anniversaryBonuses.remove(anniversaryBonus);
  }

  @action async updateEffectiveDateType(effectiveDateType, updateForecast) {
    this.effectiveDateType = effectiveDateType;
    await updateForecast();
  }

  @action updateAccrualStart(accrualStart) {
    this.typePolicy.accrualStart = accrualStart;

    if (accrualStart === 'policy_holder_start_date') {
      this.effectiveDateType = 'today';
    }
  }

  @action async updateForecastDate(forecastDate, updateForecast) {
    this.forecastDate = forecastDate;
    await updateForecast();
  }

  @action async updateForecastingEmployee(employeeId, updateForecast) {
    this.forecastingEmployeeId = employeeId;
    await updateForecast();
  }

  @action async forecastPolicy() {
    if (!this.forecastingEmployeeId || !this.effectiveDate || !this.forecastDate) return;

    this.isForecastingEmployee = true;

    const {model} = await this.store.post(
      endpoints.TIME_OFF.FORECAST_UPDATE_POLICY.with(this.forecastingEmployeeId),
      types.TIME_OFF.POLICY_CHANGE_FORECAST,
      {
        effectiveDate: moment(this.effectiveDate).format('YYYY-MM-DD'),
        forecastDate: moment(this.forecastDate).format('YYYY-MM-DD'),
        timeOffPolicy: this.policy
      }
    );

    this.forecastedValues = model;
    this.isForecastingEmployee = false;
  }

  @action async forecastTypePolicy() {
    if (!this.forecastingEmployeeId || !this.effectiveDate || !this.forecastDate) return;

    this.isForecastingEmployee = true;

    const {model} = await this.store.post(
      endpoints.TIME_OFF.TYPE_POLICIES.FORECAST_UPDATE.with({
        id: this.typePolicy.id,
        effectiveDate: this.effectiveDate,
        forecastDate: this.forecastDate,
        employeeId: this.forecastingEmployeeId
      }),
      types.TIME_OFF.POLICY_CHANGE_FORECAST,
      this.typePolicy.pick(this.typePolicyProps)
    );

    this.forecastedValues = model;
    this.isForecastingEmployee = false;
  }

  @computed get sortedTypes() {
    if (!this.timeOffTypes || !this.forecastedValues) return [];

    return _.chain(this.timeOffTypes)
      .filter(type => _.includes(Object.keys(this.forecastedValues.beforeValues), type.id))
      .sortBy('order')
      .value();
  }

  @computed get predictedAccountRows() {
    if (!this.forecastedValues) return [];

    return this.sortedTypes.map(type => ({
      id: type.id,
      name: type.name,
      oldValue: this.balanceView(this.forecastedValues.beforeValues[type.id]),
      newValue: this.balanceView(this.forecastedValues.afterValues[type.id])
    }));
  }

  @computed get willApprovePendingRequests() {
    return (this.oldTypePolicy.approvalType === 'single' || this.oldTypePolicy.approvalType === 'two_stage') && this.typePolicy.approvalType === 'auto';
  }

  @computed get willModifySecondStageRequests() {
    return (this.oldTypePolicy.approvalType === 'two_stage' && this.typePolicy.approvalType === 'two_stage') && (this.typePolicy.secondStageApprovalType !== this.oldTypePolicy.secondStageApprovalType || _.get(this.typePolicy.secondStageApprovalAssigneeUser, 'id') !== _.get(this.oldTypePolicy.secondStageApprovalAssigneeUser, 'id'));
  }

  @computed get willApproveSecondStageRequests() {
    return this.oldTypePolicy.approvalType === 'two_stage' && this.typePolicy.approvalType === 'single';
  }

  @computed get displayApprovalChangeWarning() {
    if (!auth.featureEnabled(':dynamic_time_off_request_reassignment')) return false;

    return !_.isEmpty(this.policy.employees) && (this.willApprovePendingRequests || this.willApproveSecondStageRequests || this.willModifySecondStageRequests);
  }

  @computed get predictedAccountColumns() {
    if (this.forecastedValues && this.forecastedValues.oldPolicyName !== this.policy.name) {
      return [
        {
          header: t('time_off.policy.edit.Time Off Type'),
          width: 4,
          attribute: 'name'
        },
        {
          attribute: 'oldValue',
          header: t('time_off.policy.edit.FORECAST_BEFORE_HEADER', { policy: this.forecastedValues.oldPolicyName || t('time_off.policy.edit.No Policy') }),
          width: 4
        },
        {
          attribute: 'newValue',
          header: t('time_off.policy.edit.FORECAST_AFTER_HEADER', { policy: this.policy.name }),
          width: 4
        }
      ];
    }

    return [
      {
        header: 'time_off.policy.edit.Time Off Type',
        width: 4,
        attribute: 'name'
      },
      {
        attribute: 'oldValue',
        header: t('time_off.policy.edit.Before'),
        width: 4
      },
      {
        attribute: 'newValue',
        header: t('time_off.policy.edit.After'),
        width: 4
      }
    ];
  }

  @computed get forecastingEmployee() {
    return _.find(this.selectedEmployees, {id: this.forecastingEmployeeId});
  }
  @computed get daysForStartMonth() {
    return new Date(new Date().getFullYear(), this.policy.startMonth, 0).getDate();
  }

  @computed get sortedTimeOffTypes() {
    return _.orderBy(this.timeOffTypes, 'order');
  }

  @computed get forecastDateOptions() {
    return [
      {
        label: 'time_off.policy.edit.TODAY',
        date: moment().format('YYYY-MM-DD'),
        dateView: calendarDate(moment())
      },
      {
        label: 'time_off.policy.edit.END_OF_POLICY_YEAR',
        date: this.policy.currentYearEndDate,
        dateView: this.policy.currentYearEndDateView
      },
      {
        label: 'time_off.policy.edit.START_OF_NEXT_POLICY_YEAR',
        date: this.policy.nextYearStartDate,
        dateView: this.policy.nextYearStartDateView
      },
    ];
  }

  @computed get effectiveDateOptions() {
    return [
      {
        label: 'time_off.policy.edit.START_OF_POLICY_YEAR',
        type: 'startOfPolicyYear',
        dateView: this.policy.currentYearStartDateView
      },
      {
        label: 'time_off.policy.edit.TODAY',
        type: 'today',
        dateView: calendarDate(this.today)
      }
    ];
  }

  @computed get effectiveDate() {
    if (this.effectiveDateType === 'startOfPolicyYear') {
      return this.policy.currentYearStartDate;
    } else if (this.effectiveDateType === 'today') {
      return this.today;
    } else {
      return null;
    }
  }

  @computed get today() {
    return moment().format('YYYY-MM-DD');
  }

  @computed get newSelectedEmployees() {
    let newSelectedEmployees = _.filter(this.selectedEmployees, employee => !_.find(this.policy.employees, {id: employee.id}));

    if (!_.isEmpty(this.forecastingEmployee)) {
      newSelectedEmployees = _.union(newSelectedEmployees, [this.forecastingEmployee]);
    }

    return newSelectedEmployees;
  }

  @computed get usesAccrualStartDate() {
    return this.accrualType === 'standard' && (this.typePolicy.accrualFrequency === 'weekly' || this.typePolicy.accrualFrequency === 'biweekly');
  }

  _initializeSteps() {
    const steps = _.clone(initialSteps);

    if (!_.isEmpty(this.policy.typePolicies)) {
      steps.push(...this.policy.sortedTypePolicies.map(t => {
        return {
          name: t.type.name,
          translateName: false,
          subStepLocation: t.id,
          useCustomLayout: true,
          location: 'time_off_rules'
        };
      }));
    }

    steps.push(...finalSteps);
    const stepsObject = new Steps(steps.map((s, index) => ({ ...s, index: index + 1 })), {enableStepSkipping: true});

    if (this.steps && this.steps.activeStep) {
      stepsObject.setActiveStep(this.steps.activeStep);
    }

    this.steps = stepsObject;

    if (this.policy.completed) {
      this.steps.enableNavigationToAllSteps();
    }
  }

  balanceView(balance) {
    if (balance === null) return t('time_off.policy.edit.N/A');
    if (balance === 'unlimited') return auth.featureEnabled(':flexible_time_off') ? t('time_off.policy.edit.Flexible') : t('time_off.policy.edit.Unlimited');

    return balance;
  }

  workdaySelected(day) {
    return this.policy.workWeekDays.includes(day);
  }

  timeOffTypeSelected(type) {
    return _.find(this.selectedTimeOffTypes, { id: type.id });
  }
}

export default PolicyEditFlowState;
