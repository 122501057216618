import React from 'react';
import {observer} from 'mobx-react';
import {auth, withState, t} from 'shared/core';
import {Flow} from 'components';
import {PolicyEditFlowState} from '../state';
import {Step} from '../components';

const PolicyEditFlow = observer(({uiState}) => {
  const {policy, steps} = uiState;

  return (
    <Flow
      name={policy.name}
      baseUrl={auth.featureEnabled(':reactify_time_off_policies') ?
        `/policies/${policy.id}/edit` :
        `/policies/${policy.id}/8c90d380-1419-4d23-9e04-3bbb64599381`
      }
      backTitle={t('time_off.policy.edit.Policies')}
      backRoute='/policies'
      steps={steps}
      component={Step}
      uiState={uiState}
      onStepChanged={step => uiState.onStepChanged(step)}
      onStepSubmitted={(step) => uiState.onStepSubmitted(step)}
    />
  );
});

export default withState(PolicyEditFlow, PolicyEditFlowState);
