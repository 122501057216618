import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormLayout, Select2, RadioButton, Input, LabelHelper, FormError, ActionLink, RadioButtonGroup, DatePicker} from 'components';
import {FormattedMessage} from 'react-intl';

const BaseAccrualRate = observer(({uiState, typePolicy}) => {
  const {errors} = uiState;

  return (
    <div className='clearfix table'>
      <div className='flex align-items-middle'>
        <div className='col-5 table-cell align-middle pr1'>
          <Input
            value={typePolicy.baseAccrualRate}
            onChange={e => typePolicy.merge({baseAccrualRate: e.target.value})}
            hasError={!!errors.baseAccrualRate}
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_off.policy.edit.day(s)')}</span>
              </div>
            }
          />
        </div>
        <div className='col-7 table-cell align-middle jumbo pl1'><FormattedMessage id={`time_off.policy.edit.Base accrual rate`}/></div>
      </div>
      <FormError message={errors.baseAccrualRate}/>
    </div>
  );
});

const AnniversaryBonus = observer(({uiState, anniversaryBonus, effectiveRateErrors, anniversaryErrors}) => {
  return (
    <div className='clearfix table mt2'>
      <div className='flex align-items-middle'>
        <div className='col-5 align-middle pr1'>
          <Input
            value={anniversaryBonus.effectiveRate}
            onChange={e => anniversaryBonus.merge({effectiveRate: parseFloat(e.target.value)})}
            hasError={!!effectiveRateErrors}
            step='1'
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_off.policy.edit.day(s)')}</span>
              </div>
            }
          />
        </div>
        <div className='col-1 md-and-below-hide align-middle center jumbo px1 text-nowrap'><FormattedMessage id={`time_off.policy.edit.after`}/></div>
        <div className='col-5 align-middle pl1'>
          <Input
            value={anniversaryBonus.anniversary}
            onChange={e => anniversaryBonus.merge({anniversary: parseFloat(e.target.value)})}
            hasError={!!anniversaryErrors}
            step='1'
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_off.policy.edit.year(s)')}</span>
              </div>
            }
          />
        </div>
        <div className='col-1 table-cell align-middle pl1'>
          <ActionLink onClick={() => uiState.removeAnniversaryBonus(anniversaryBonus)} className='submarine'><i className='material-icons mx1 h2'>{'delete'}</i></ActionLink>
        </div>
      </div>
      <div className='flex align-items-middle'>
        <div className='col-5 pr1'>
          <FormError message={effectiveRateErrors}/>
        </div>
        <div className='col-1 md-and-below-hide'/>
        <div className='col-6 pl1'>
          <FormError message={anniversaryErrors}/>
        </div>
      </div>
    </div>
  );
});


const AccrualRates = observer(({uiState, typePolicy}) => {
  const {errors} = uiState;

  return (
    <div>
      <div className='table'>
        <div className='md-col-5 table-cell'>
          <LabelHelper label={t('time_off.policy.edit.Accrual Rates')}/>
        </div>
        {!_.isEmpty(typePolicy.anniversaryBonuses) && <div className='md-and-above-hide col-7 table-cell pl1'>
          <LabelHelper label={t('time_off.policy.edit.Milestones hit after')}/>
        </div>}
      </div>
      <BaseAccrualRate typePolicy={typePolicy} uiState={uiState}/>
      {typePolicy.anniversaryBonuses.map((anniversaryBonus, index) => (
        <AnniversaryBonus
          key={index}
          uiState={uiState}
          effectiveRateErrors={_.get(errors, `anniversaryBonuses[${index}].effectiveRate`)}
          anniversaryErrors={_.get(errors, `anniversaryBonuses[${index}].anniversary`)}
          anniversaryBonus={anniversaryBonus}
        />
      ))}
      <div className='mt1'>
        <ActionLink onClick={() => uiState.addAnniversaryBonus()}>
          <FormattedMessage id={`time_off.policy.edit.+ Add a milestone`}/>
        </ActionLink>
      </div>
    </div>
  );
});


const AccrualForm = observer(({uiState, accrualType}) => {
  const {typePolicy, errors, usesAccrualStartDate} = uiState;

  if (!accrualType === 'standard') return null;

  return (
    <FormLayout>
      <AccrualRates uiState={uiState} typePolicy={typePolicy}/>
      <div className='flex flex-col md-flex-row row-gap-2'>
        <div className='col col-12 md-col-6 md-pr1'>
          <Select2
            label='time_off.policy.edit.Frequency'
            value={typePolicy.accrualFrequency}
            onChange={e => typePolicy.merge({accrualFrequency: e.target.value})}
            errorMessage={errors.accrualFrequency}
            >
            <option value='annual'>{t('time_off.policy.edit.accrual_frequency.annually')}</option>
            <option value='monthly'>{t('time_off.policy.edit.accrual_frequency.monthly')}</option>
            <option value='semimonthly'>{t('time_off.policy.edit.accrual_frequency.semimonthly')}</option>
            <option value='biweekly'>{t('time_off.policy.edit.accrual_frequency.biweekly')}</option>
            <option value='weekly'>{t('time_off.policy.edit.accrual_frequency.weekly')}</option>
          </Select2>
        </div>
        <div className='col col-12 md-col-6 md-pl1'>
          <Select2
            label='time_off.policy.edit.Earned'
            value={String(typePolicy.accrueUpfront)}
            onChange={e => typePolicy.accrueUpfront = e.target.value}
            errorMessage={errors.accrueUpfront}
          >
            <option value='true'>{t('time_off.policy.edit.At the start of the period')}</option>
            <option value='false'>{t('time_off.policy.edit.At the end of the period')}</option>
          </Select2>
        </div>
      </div>
      <div className='flex flex-col md-flex-row row-gap-2'>
        {usesAccrualStartDate && <div className='col col-12 md-col-6 md-pr1'>
          <DatePicker className='full-width'
            label='time_off.policy.edit.Starting on'
            onChange={date => typePolicy.merge({accrualStartDate: date})}
            value={typePolicy.accrualStartDate}
            errorMessage={errors.accrualStartDate}
          />
        </div>}
        <div className={`col col-12 md-col-6 ${usesAccrualStartDate ? 'md-pl1' : ''}`}>
          <RadioButtonGroup
            name='prorationEnabled'
            label='time_off.policy.edit.Proration'
            selectedValue={typePolicy.prorationEnabled}
            onChange={(name, value) => typePolicy.merge({prorationEnabled: value})}
          >
            <RadioButton className='table-cell pt1 pr2' value={false}><FormattedMessage id='time_off.policy.edit.No'/></RadioButton>
            <RadioButton className='table-cell pt1 pl2' value={true}><FormattedMessage id='time_off.policy.edit.Yes'/></RadioButton>
          </RadioButtonGroup>
        </div>
      </div>
    </FormLayout>
  );
});

export default AccrualForm;
