import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, types} from 'shared/core';
import EmployeeTableSelectorState from '../state/EmployeeTableSelectorState';
import {Spinner, FormLayout, FormError, InteractiveContext, FancyFilter} from 'components';
import TransferButtons from './TransferButtons';
import {Employee} from 'stores/employees';
import {FilterRow} from 'components/tables/interactive/components/FilterRow';
import _ from 'lodash';

const EmployeeSelector = ({employees, values, title, onSelectionChange, emptyStateMessage, className = ''}) => (
  <div className={`${className} col-5 flex flex-col`}>
    <div className='flex justify-content-center align-items-middle border-bottom jumbo p1'>{title}</div>
    {employees.length > 0 ? (
      <div className='border-none relative'>
        <select
          className='full-width full-height border-none'
          multiple
          size='8'
          value={values}
          onChange={(e) => onSelectionChange(Array.from(e.target.selectedOptions))}
        >
          {employees.map(employee => (
            <option className='h5 regular border-bottom py2 pl3' key={employee.id} value={employee.id}>
              {employee.name}
            </option>
          ))}
        </select>
      </div>
    ) : (
      <div className='flex flex-grow justify-content-center align-items-middle full-width full-height border-none relative'>
        <div className='h3 jumbo'>{emptyStateMessage}</div>
      </div>
    )}
  </div>
);

const Selector = observer(({uiState, filters, ...rest}) => {
  const {
    canAddAll,
    canRemoveAll,
    canAdd,
    canRemove,
    highlightedIncludedEmployeeIds,
    highlightedAvailableEmployeeIds,
    sortedAvailableEmployees,
    sortedFilteredIncludedEmployees
  } = uiState;

  return (
    <div className='border rounded'>
      {!_.isEmpty(filters) && <FilterRow className='p2' searchable style={{margin: 0}} fancyFiltersEnabled {...rest}>
        <FancyFilter filters={filters}/>
      </FilterRow>}
      <div className='flex'>
        <EmployeeSelector
          className='border-right'
          title={t('components.employee_table_selector.Available Employees')}
          employees={sortedAvailableEmployees}
          values={highlightedAvailableEmployeeIds}
          onSelectionChange={(selectedOptions) => uiState.updateHighlightedAvailableEmployeeIds(selectedOptions)}
          emptyStateMessage={t('components.employee_table_selector.No available employees')}
        />
        <div className='col-2 flex justify-content-center align-items-middle center'>
          <TransferButtons
            uiState={uiState}
            canAddAll={canAddAll}
            canRemoveAll={canRemoveAll}
            canAdd={canAdd}
            canRemove={canRemove}
          />
        </div>
        <EmployeeSelector
          className='border-left'
          title={t('components.employee_table_selector.Selected Employees')}
          employees={sortedFilteredIncludedEmployees}
          values={highlightedIncludedEmployeeIds}
          onSelectionChange={(selectedOptions) => uiState.updateHighlightedIncludedEmployeeIds(selectedOptions)}
          emptyStateMessage={t('components.employee_table_selector.No selected employees')}
        />
      </div>
    </div>
  );
});

const EmployeeTableSelector = observer(({uiState, endpoint, filters}) => {
  const {errorMessage} = uiState;

  return (
    <FormLayout>
      <InteractiveContext
        uiState={uiState}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        onRefresh={args => uiState.updateInteractiveContextState(args)}
        proxy={{
          type: 'async',
          modelType: types.EMPLOYEE,
          model: Employee,
          endpoint
        }}
        fancyFiltersEnabled
      >
        <Selector uiState={uiState} filters={filters}/>
        <FormError message={errorMessage}/>
      </InteractiveContext>
    </FormLayout>
  );
});

export default withState(EmployeeTableSelector, EmployeeTableSelectorState, {
  spinner: Spinner
});
